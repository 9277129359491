import { Image } from "@material-tailwind/react";
import { Skeleton } from "antd";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Events() {
  const [eventList, setEventList] = useState(null);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria",
        { criteria: {} }
      );

      setEventList(response.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <section className="relative bg-gray-100">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl" style={{border: '1px solid grey'}}>
          <div className="rounded-2xl overflow-hidden">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Event Name
                  </th>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Create Date
                  </th>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Network
                  </th>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Markers
                  </th>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Max Mint Count
                  </th>
                  <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Active
                  </th>
                </tr>
              </thead>
              <tbody>
                {!eventList ? (
                  <tr>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <Skeleton.Input active={true} style={{ width: "100px" }} />
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <Skeleton.Input active={true} style={{ width: "100px" }} />
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <Skeleton.Input active={true} style={{ width: "100px" }} />
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full border-2 border-white">
                          <Skeleton.Avatar active={true} />
                        </div>
                        <div className="w-10 h-10 rounded-full border-2 border-white -ml-4">
                          <Skeleton.Avatar active={true} />
                        </div>
                      </div>
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <Skeleton.Input active={true} style={{ width: "100px" }} />
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <Skeleton.Input active={true} style={{ width: "100px" }} />
                    </td>
                  </tr>
                ) : eventList.length > 0 ? (
                  eventList.map((event, index) => {
                    return (
                      <tr key={index}>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          <Link to={`/event/${event.id}`}>{event.name}</Link>
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {(new Date(event.createTime)).toLocaleString("en-US")}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {event.network}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          <div className="flex">
                            {event.markerImageList &&
                              event.markerImageList.map((markerImage, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-10 h-10 rounded-full border-2 border-white"
                                  >
                                    <Image
                                      src={`https://ipfs.io/ipfs/${markerImage.urlPath}`}
                                      rounded
                                      alt="..."
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {event.maxMintCount}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {event.enabled ? "Yes" : "No"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>No event found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
