import { Wrapper } from "@googlemaps/react-wrapper";
import axios from "axios";
import GoogleMap from "components/GoogleMap";
import ModalMarkerEdit from "components/ModalMarkerEdit";
import { PINYWORLD_API_END_POINT } from "constant";
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/pinyworld.css";
import * as pinyActions from "../../store/actions/actionPiny";
import ModalWalletConnectWarning from "../ModalWalletConnectWarning";

export default function Content() {
  const dispatch = useDispatch();

  const { isAuthenticated } = useMoralis();
  const { pinModeActive, currentMarkerImage } = useSelector(
    (state) => state.piny
  );
  // console.log("[Content.js] From REDUX State - pinModeActive: " + pinModeActive + ", walletConnected: " + walletConnected + ", isAuthenticated: " + isAuthenticated);
  // console.log("[Content.js] useMoralis() - isAuthenticated: " + isAuthenticated + ", isWeb3Enabled: " + isWeb3Enabled);

  const [marker, setMarker] = useState();

  const [markerList, setMarkerList] = useState();
  const [queryMarkerByAreaTimeout, setQueryMarkerByAreaTimeout] = useState();

  const [walletConnectWarningModal, setWalletConnectWarningModal] =
    useState(false);
  const [markerEditModal, setMarkerEditModal] = useState(false);

  useEffect(() => {
    // console.log("[Content.js] in useEffect() - isWeb3Enabled: " + isWeb3Enabled);
  }, [isAuthenticated]);

  const toggleMapMode = async (event) => {
    // console.log("toggleMapMode() ------- piny mode was: " + pinModeActive + " - isAuthenticated: " + isAuthenticated);
    const newMode = pinModeActive === true ? false : true;

    if (!isAuthenticated && newMode) {
      setWalletConnectWarningModal(true);
      return;
    }

    if (isAuthenticated && newMode) {
      setMarker(null);
    }

    await dispatch(pinyActions.changeMapMode(newMode));
  };

  const triggerSetMarkerEditModal = () => {
    setMarker(null);
    dispatch(pinyActions.setMarkerSet(false));
    setMarkerEditModal(true);
  };

  const triggerSetCurrentMarkerImage = (newImage) => {
    dispatch(pinyActions.setCurrentMarkerImage(newImage));
  };

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  const onMapBoundsChanged = (map) => {
    if (!map) {
      return;
    }

    if (queryMarkerByAreaTimeout) {
      clearTimeout(queryMarkerByAreaTimeout);
    } else {
      setQueryMarkerByAreaTimeout(
        setTimeout(() => {
          queryMarkerByArea();
        }, 2000)
      );
    }

    const queryMarkerByArea = () => {
      axios
        .post(PINYWORLD_API_END_POINT + "marker/query/criteria", {
          criteria: {
            topRight: {
              longitude: map.getBounds().getNorthEast().lng(),
              latitude: map.getBounds().getNorthEast().lat(),
            },
            bottomLeft: {
              longitude: map.getBounds().getSouthWest().lng(),
              latitude: map.getBounds().getSouthWest().lat(),
            },
          },
        })
        .then((response) => {
          setMarkerList(
            response.data.map((marker) => (
              <Marker
                key={marker.id}
                icon={{
                  url: "https://ipfs.io/ipfs/" + marker.imageUrl,
                  scaledSize: { width: 45, height: 70 },
                }}
                position={{
                  lng: marker.coordinate.longitude,
                  lat: marker.coordinate.latitude,
                }}
              />
            ))
          );
        });
    };
  };

  const onMapClick = (event) => {
    // console.log("[Content.js] in onMapClick() - " + event.latLng);

    setMarker(
      <Marker
        position={event.latLng}
        icon={{
          url: currentMarkerImage,
          scaledSize: { width: 45, height: 70 },
        }}
      />
    );
    dispatch(
      pinyActions.updateCurrentMarkerInfo(
        event.latLng,
        undefined,
        undefined,
        undefined
      )
    );
  };

  const onZoomChanged = (map) => {
    console.log("[Content.js] in onZoomChanged(): " + map.getZoom());
    dispatch(
      pinyActions.updateCurrentMarkerInfo(
        undefined,
        map.getZoom(),
        undefined,
        undefined
      )
    );
  };

  // console.log("before return: " + pinModeActive);

  return (
    <section className="relative py-16 bg-gray-100">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="rounded-2xl overflow-hidden">
            <Wrapper
              apiKey={"AIzaSyBtVHcUCL8ZPPzvsP23n3Mby9bwGcaRKUg"}
              render={render}
              language="en"
            >
              <GoogleMap
                onClick={onMapClick}
                onBoundsChanged={onMapBoundsChanged}
                onZoomChanged={onZoomChanged}
                toggleMapMode={toggleMapMode}
                pinModeActive={pinModeActive}
                setMarkerEditModal={triggerSetMarkerEditModal}
                currentMarkerImage={currentMarkerImage}
              >
                {pinModeActive ? marker : markerList}
              </GoogleMap>
            </Wrapper>

            <ModalWalletConnectWarning
              walletConnectWarningModal={walletConnectWarningModal}
              setWalletConnectWarningModal={setWalletConnectWarningModal}
            />

            <ModalMarkerEdit
              markerEditModal={markerEditModal}
              setMarkerEditModal={setMarkerEditModal}
              setCurrentMarkerImage={triggerSetCurrentMarkerImage}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export function Marker(options) {
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
}
