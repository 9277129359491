import React from "react";

class GoogleMapViewOnly extends React.Component {

    state = {
        map: undefined,
        eventListenerAdded: false,
        controlText: undefined,
        currentMarkerAdded: false,
        currentMarkerImage: undefined
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        // console.log("GoogleMapViewOnly - constructor()");
    }

    componentDidMount() {
        // console.log("GoogleMapViewOnly - componentDidMount()");

        const onClick = this.props.onClick;
        const onBoundsChanged = this.props.onBoundsChanged;
        const onZoomChanged = this.props.onZoomChanged;
        const mapViewOnly = this.props.mapViewOnly;

        const latitude = this.props.latitude;
        const longitude = this.props.longitude;
        const zoom = this.props.zoom;

        if (this.ref.current && !this.state.map) {
            // console.log("GoogleMapViewOnly - componentDidMount() IN");

            let newMap = new window.google.maps.Map(this.ref.current, {
                zoom: (zoom !== undefined ? zoom : 3),
                center: {
                    lat: (latitude !== undefined ? latitude : 30.44867367928756),
                    lng: (longitude !== undefined ? longitude : 17.55859375),
                  },
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
            });

            if (onClick) {
                newMap.addListener("click", onClick);
            }

            if (onBoundsChanged) {
                newMap.addListener("bounds_changed", () => onBoundsChanged(newMap));
            }

            if (onZoomChanged) {
                newMap.addListener("zoom_changed", () => onZoomChanged(newMap));
            }

            this.setState({
                map: newMap,
                eventListenerAdded: true
            });

            mapViewOnly.current = newMap;
        }
    }

    componentWillUnmount() {
        // console.log("GoogleMapViewOnly - componentWillUnmount()");
    }

    componentWillUpdate() {
        // console.log("GoogleMapViewOnly - componentWillUpdate() - " + this.state.map);
        if (this.state.eventListenerAdded) {
            window.google.maps.event.clearListeners(this.state.map, 'click');
        }
    }

    componentDidUpdate() {
        // console.log("GoogleMapViewOnly - componentDidUpdate() - " + this.state.map);

        if (this.state.eventListenerAdded) {
            // console.log("GoogleMapViewOnly - componentDidUpdate() - " + this.state.map + " - TRUE - " + pinModeActive);
        }
    }

    render() {
        
        // console.log("GoogleMapViewOnly - render()");
        
        const children = this.props.children;
        const map = this.state.map;        

        return (
            <>
                <div ref={this.ref} id="map" style={{ width: "100%", height: "700px" }}>
                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            // set the map prop on the child component
                            return React.cloneElement(child, { map });
                        }
                    })}
                </div>
            </>
        );
    }
}

export default GoogleMapViewOnly;