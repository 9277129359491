import React from "react";
import { Range } from "react-range"

export default function SettingSlider(props) {
    
    const [values, setValues] = React.useState([1]);

    return (
        <Range
            step={props.step}
            min={props.min}
            max={props.max}
            values={values}
            onChange={(values) => {
                props.onChange(values);
                setValues(values);
            }}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    className="w-full h-2 pr-2 bg-gray-500 rounded-md"
                >
                    {children}
                </div>
            )}
            renderThumb={({ props }) => (
                <div
                    {...props}
                    className="w-4 h-4 transform translate-x-10 bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                />
            )}
        />
    );

}