import { Button, Dropdown, Menu, Space } from 'antd';
import React from "react";
import Fonts from '../../helpers/fonts';
import SettingColorPicker from "../modalmarker/SettingColorPicker";
import SettingSlider from "../modalmarker/SettingSlider";

export default function SettingSelectedTextbox(props) {

    const fonts = Fonts.getFonts();

    const fSizeArray = Array.from({ length: 60 }, (_, i) => i + 1);

    const handleFontFamily = (e) => {
        props.activeObj.set({
            fontFamily: e.key
        });
        props.activeObj.canvas.renderAll();
    }

    const handleFontSize = (e) => {
        props.activeObj.set({
            fontSize: e.key
        });
        props.activeObj.canvas.renderAll();
    }

    const handleOpacity = (value) => {
        props.activeObj.set({
            opacity: value
        });

        props.activeObj.canvas.renderAll();
    }

    const handleColor = (value) => {
        props.activeObj.set({
            fill: value
        });

        props.activeObj.canvas.renderAll();
    }

    const menuFontFamily = (
        <div style={{ overflow: 'scroll', height: 200 }}>
            <Menu onClick={handleFontFamily}>
                {
                    Object.keys(fonts.default).map(font => {
                        return (
                            <Menu.Item key={fonts.default[font].name} style={{ whiteSpace: 'normal', height: '30px', marginTop: 0, marginBottom: 0 }}>
                                <p style={{ fontFamily: fonts.default[font].name }}>{fonts.default[font].name}</p>
                            </Menu.Item>
                        );
                    })
                }
            </Menu>
        </div>
    );

    const menuFontSize = (
        <div style={{ overflow: 'scroll', height: 200 }}>
            <Menu onClick={handleFontSize}>
                {
                    Object.keys(fSizeArray).map(fsize => {
                        return (
                            <Menu.Item key={fSizeArray[fsize]} style={{ whiteSpace: 'normal', height: '30px', marginTop: 0, marginBottom: 0 }}>
                                <p>{fSizeArray[fsize]}</p>
                            </Menu.Item>
                        );
                    })
                }
            </Menu>
        </div>
    );

    return (
        <div>
            Modify Text
            <div className="p-1 rounded-md" style={{ border: '1px solid grey' }}>
                <div className="flex flex-col">
                    <div className="flex flex-row p-2">
                        <div className="flex justify-center items-center">
                            Opacity
                        </div>
                        <div className="flex items-center ml-2" style={{ flexGrow: 1 }}>
                            <SettingSlider min={0} max={1} step={0.1}
                                onChange={handleOpacity}
                                initialValue={[0]} />
                        </div>
                    </div>
                    <div className="flex flex-row p-2">
                        <div className="flex justify-center items-center">
                            Color
                        </div>
                        <div className="flex justify-center items-center ml-2" style={{flexGrow: 1 }}>
                            <SettingColorPicker className="py-3 px-3" onChange={handleColor} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <Space wrap>
                            <Dropdown trigger="click" overlay={menuFontFamily}>
                                <Button size="small">
                                    Font Family
                                </Button>
                            </Dropdown>
                        </Space>

                        <Space wrap>
                            <Dropdown trigger="click" overlay={menuFontSize}>
                                <Button size="small">
                                    Font Size
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    );
}