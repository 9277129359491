import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Image from "@material-tailwind/react/Image";
import Progress from "@material-tailwind/react/Progress";
import Team1 from "assets/img/team-1-800x800.jpg";
import Team2 from "assets/img/team-2-800x800.jpg";
import Team3 from "assets/img/team-3-800x800.jpg";
import Team4 from "assets/img/team-4-470x470.png";
import { Button } from "@material-tailwind/react";
import DefaultFooter from "components/DefaultFooter";
import DefaultNavbar from "components/DefaultNavbar";
import Header from "components/mainpage/Header";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { Skeleton } from "antd";

export default function MyMarkers() {
  const { isWeb3Enabled, account } = useMoralis();

  const [myMarkerList, setMyMarkerList] = useState(null);

  const fetchMyMarkers = useCallback(async () => {
    if (!isWeb3Enabled || !account) {
      return;
    }

    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "marker/query/criteria",
        {
          criteria: {
            ownerList: [account],
          },
        }
      );

      setMyMarkerList(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [account, isWeb3Enabled]);

  useEffect(() => {
    fetchMyMarkers();
  }, [fetchMyMarkers]);

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <section className="relative py-16 bg-gray-100">
          <div
            className="max-w-7xl px-4 mx-auto"
            style={{ marginTop: "-224px" }}
          >
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">My Markers</h2>
                </div>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Marker Image
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Marker Name
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Network
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Mint Date
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Event Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!myMarkerList ? (
                        <tr>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <div className="w-10 h-10 rounded-full border-2 border-white">
                              <Skeleton.Avatar active={true} />
                            </div>
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                        </tr>
                      ) : myMarkerList.length > 0 ? (
                        myMarkerList.map((marker, index) => {
                          return (
                            <tr key={index}>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                <div style={{ width: "50px" }}>
                                  <Image
                                    src={`https://ipfs.io/ipfs/${marker.imageUrl}`}
                                    rounded
                                    alt="..."
                                  />
                                </div>
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {marker.name}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {marker.network}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {marker.createTime}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {marker.eventName}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
