import React from "react";
import SettingSelectedTextbox from "./SettingSelectedTextbox";
import SettingSelectedEmoji from "./SettingSelectedEmoji";
import SettingSelectedImage from "./SettingSelectedImage";

const getTitlePart = (activeObj) => {
    const type = activeObj.get('type');
    if (type === "image") {
        return "Image";
    }
    if (type === "textbox") {
        if (activeObj.isEmoji) {
            return "Emoji"
        }
        else {
            return "Text"
        }
    }

    return "Undefined";
}

export default function SettingModifySelected(props) {
    const objType = props.activeObj.get('type');
    const titePart = getTitlePart(props.activeObj);


    console.log("Active OBJ: " + objType);
    return (
        <>
            <div className="mt-5">
                {titePart === "Text" && <SettingSelectedTextbox activeObj={props.activeObj} />}
                {titePart === "Emoji" && <SettingSelectedEmoji activeObj={props.activeObj} />}
                {titePart === "Image" && <SettingSelectedImage activeObj={props.activeObj} />}
            </div>
        </>
    );
}