import React from "react";

class GoogleMap extends React.Component {

    state = {
        map: undefined,
        eventListenerAdded: false,
        controlText: undefined,
        currentMarkerAdded: false,
        currentMarkerImage: undefined
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        // console.log("GoogleMap - constructor()");
    }

    componentDidMount() {
        // console.log("GoogleMap - componentDidMount()");

        const onClick = this.props.onClick;
        const onBoundsChanged = this.props.onBoundsChanged;
        const onZoomChanged = this.props.onZoomChanged;
        const toggleMapMode = this.props.toggleMapMode;
        const pinModeActive = this.props.pinModeActive;

        const latitude = this.props.latitude;
        const longitude = this.props.longitude;
        const zoom = this.props.zoom;

        if (this.ref.current && !this.state.map) {
            // console.log("GoogleMap - componentDidMount() IN");

            let newMap = new window.google.maps.Map(this.ref.current, {
                zoom: (zoom !== undefined ? zoom : 3),
                center: {
                    lat: (latitude !== undefined ? latitude : 30.44867367928756),
                    lng: (longitude !== undefined ? longitude : 17.55859375),
                },
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
            });

            if (onClick) {
                newMap.addListener("click", onClick);
            }

            if (onBoundsChanged) {
                newMap.addListener("bounds_changed", () => onBoundsChanged(newMap));
            }

            if (onZoomChanged) {
                newMap.addListener("zoom_changed", () => onZoomChanged(newMap));
            }

            const centerControlDiv = document.createElement("div");

            const controlUI = document.createElement("div");

            controlUI.style.backgroundColor = "#fff";
            controlUI.style.border = "2px solid #fff";
            controlUI.style.borderRadius = "3px";
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlUI.style.cursor = "pointer";
            controlUI.style.marginTop = "8px";
            controlUI.style.marginRight = "8px";
            controlUI.style.marginBottom = "22px";
            controlUI.style.textAlign = "center";
            controlUI.title = "Click to recenter the map";
            centerControlDiv.appendChild(controlUI);

            const controlText = document.createElement("div");

            controlText.style.color = "rgb(25,25,25)";
            controlText.style.fontFamily = "Roboto,Arial,sans-serif";
            controlText.style.paddingLeft = "5px";
            controlText.style.paddingRight = "5px";
            controlText.style.paddingTop = "5px";
            controlText.style.paddingBottom = "5px";
            controlText.style.minWidth = "40px";
            if (pinModeActive) {
                controlText.innerHTML = "<i class=\"fas fa-map-pin fa-2x\"></i>";
            } else {
                controlText.innerHTML = "<i class=\"fas fa-eye fa-2x\"></i>";
            }
            controlUI.appendChild(controlText);

            controlText.addEventListener("click", toggleMapMode, false);

            newMap.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);

            this.setState({
                map: newMap,
                eventListenerAdded: true,
                controlText: controlText
            });
        }
    }

    componentWillUnmount() {
        // console.log("GoogleMap - componentWillUnmount()");
    }

    componentWillUpdate() {
        // console.log("GoogleMap - componentWillUpdate() - " + this.state.map);
        if (this.state.eventListenerAdded) {
            const toggleMapMode = this.props.toggleMapMode;
            // console.log("GoogleMap - componentWillUpdate() - " + this.state.map + " - TRUE - " + pinModeActive);
            this.state.controlText.removeEventListener("click", toggleMapMode, false);
            window.google.maps.event.clearListeners(this.state.map, 'click');
        }
    }

    componentDidUpdate() {
        // console.log("GoogleMap - componentDidUpdate() - " + this.state.map);
        const toggleMapMode = this.props.toggleMapMode;
        const pinModeActive = this.props.pinModeActive;

        if (this.state.eventListenerAdded) {
            // console.log("GoogleMap - componentDidUpdate() - " + this.state.map + " - TRUE - " + pinModeActive);
            this.state.controlText.addEventListener("click", toggleMapMode, false);
            
            if (pinModeActive) {
                this.state.map.addListener("click", this.props.onClick);
                this.state.controlText.innerHTML = "<i class=\"fas fa-map-pin fa-2x\"></i>";

                if (!this.state.currentMarkerAdded || (this.state.currentMarkerAdded && this.state.currentMarkerImage !== this.props.currentMarkerImage)) {
                    
                    if(this.state.currentMarkerAdded && this.state.currentMarkerImage !== this.props.currentMarkerImage) {
                        this.state.map.controls[window.google.maps.ControlPosition.TOP_LEFT].clear();
                    }
                    
                    const currentMarkerControlDiv = document.createElement("div");
                    currentMarkerControlDiv.className = "currentMarkerControlDivCLASS";

                    const controlUI = document.createElement("div");

                    controlUI.style.backgroundColor = "#fff";
                    controlUI.style.border = "2px solid #fff";
                    controlUI.style.borderRadius = "3px";
                    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
                    controlUI.style.cursor = "pointer";
                    controlUI.style.marginTop = "8px";
                    controlUI.style.marginLeft = "8px";
                    controlUI.style.marginBottom = "22px";
                    controlUI.style.textAlign = "center";
                    controlUI.style.position = "relative";

                    const currentMarkerImg = document.createElement("img");

                    currentMarkerImg.src = this.props.currentMarkerImage;
                    currentMarkerImg.style.width = "90px";
                    currentMarkerImg.style.height = "140px";

                    controlUI.appendChild(currentMarkerImg);

                    const editIconDIV = document.createElement("div");

                    const editIconI = document.createElement("i");
                    editIconI.className="fas fa-edit fa-2x currentMarkerEditIcon";

                    editIconDIV.style.position = "absolute";
                    editIconDIV.style.bottom = "0";
                    editIconDIV.style.right = "0";
                    
                    editIconDIV.appendChild(editIconI);

                    controlUI.appendChild(editIconDIV);

                    currentMarkerControlDiv.appendChild(controlUI);
                    controlUI.addEventListener("click", this.props.setMarkerEditModal.bind(this), false);

                    this.state.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(currentMarkerControlDiv);
                    this.state.currentMarkerAdded = true;
                    this.state.currentMarkerImage = this.props.currentMarkerImage;
                }





            } else {
                this.state.controlText.innerHTML = "<i class=\"fas fa-eye fa-2x\"></i>";
                this.state.map.controls[window.google.maps.ControlPosition.TOP_LEFT].clear();
                this.state.currentMarkerAdded = false;
            }
        }
    }

    render() {
        // console.log("GoogleMap - render()");

        const children = this.props.children;
        const map = this.state.map;

        return (
            <>
                <div ref={this.ref} id="map" style={{ width: "100%", height: "700px" }}>
                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            // set the map prop on the child component
                            return React.cloneElement(child, { map });
                        }
                    })}
                </div>
            </>
        );
    }
}

export default GoogleMap;