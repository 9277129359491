import Button from "@material-tailwind/react/Button";
import Popover from "@material-tailwind/react/Popover";
import PopoverBody from "@material-tailwind/react/PopoverBody";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";
import React, { useRef, useState } from "react";
import { SketchPicker } from 'react-color';

export default function SettingColorPicker(props) {

    const buttonRef = useRef();
    const [color, setColor] = useState(props.value || 'rgba(0, 0, 0, 1)');

    const onChangeHandler = (color) => {
        const valueType = 'string';
        const { onChange } = props;

        let newColor;
        if (valueType === 'string') {
            newColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
        } else {
            newColor = color.rgb;
        }

        setColor(newColor);
        onChange(newColor);
    }

    return (
        <>
            <Button className={props.className + " rounded-full shadow-none"} ref={buttonRef} ripple="light" style={{ backgroundColor: color }}>
                <i className="fa fa-crosshairs x" aria-hidden="true"></i>
            </Button>

            <Popover placement="bottom" ref={buttonRef}>
                <PopoverContainer>
                    <PopoverBody>
                        <SketchPicker color={color} onChange={onChangeHandler} />
                    </PopoverBody>
                </PopoverContainer>
            </Popover>
        </>
    );

}
