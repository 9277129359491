import Nav from "@material-tailwind/react/Nav";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import { useState } from "react";
import { useSelector } from "react-redux";
import WalletInfo from "./WalletInfo";
import MintActions from "./MintActions";
import { Link } from "react-router-dom";
import pinyLogo from "../assets/img/logo/piny-white-trans.png";

export default function DefaultNavbar() {
  const { pinModeActive, isMarkerSet } = useSelector((state) => state.piny);
  const [openNavbar, setOpenNavbar] = useState(false);

  // console.log("IN Navbar - pinModeActive: " + pinModeActive + ", isMarkerSet: " + isMarkerSet);

  return (
    <Navbar color="transparent" navbar>
      <NavbarContainer>
        <NavbarWrapper>
          <div style={{ paddingLeft: "20px" }}>
            <NavbarBrand>
              <Link to="/">
                <img src={pinyLogo} width={110} />
              </Link>
            </NavbarBrand>
            <NavbarToggler
              onClick={() => setOpenNavbar(!openNavbar)}
              color="white"
            />
          </div>
        </NavbarWrapper>

        {isMarkerSet && pinModeActive && <MintActions />}
        
        <Nav>
          <div className="flex flex-col z-50 lg:flex-row lg:items-center">
            <WalletInfo />
          </div>
        </Nav>
      </NavbarContainer>
    </Navbar>
  );
}
