import { ZERO_ADDRESS } from "./formatters";

export const coinMetadata = {
  ETH: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: "18",
    address: ZERO_ADDRESS,
  },
  AVAX: { name: "AVAX", symbol: "AVAX", decimals: "18", address: ZERO_ADDRESS },
  BNB: { name: "BNB", symbol: "BNB", decimals: "18", address: ZERO_ADDRESS },
  MATIC: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    address: ZERO_ADDRESS,
  },
};

export const networkCoin = {
  "0x1": coinMetadata["ETH"],
  "0x3": coinMetadata["ETH"],
  "0x4": coinMetadata["ETH"],
  "0x2a": coinMetadata["ETH"],
  "0x5": coinMetadata["ETH"],
  "0x539": coinMetadata["ETH"],
  "0xa86a": coinMetadata["AVAX"],
  "0xa869": coinMetadata["AVAX"],
  "0x38": coinMetadata["BNB"],
  "0x61": coinMetadata["BNB"],
  "0x89": coinMetadata["MATIC"],
  "0x13881": coinMetadata["MATIC"],
};

export const getCoinMetadata = (network) => {
  return networkCoin[network];
};
