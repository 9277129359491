import React from "react";
import SettingSlider from "../modalmarker/SettingSlider";

export default function SettingSelectedImage(props) {

    const handleOpacity = (value) => {
        props.activeObj.set({
            opacity: value
        });

        props.activeObj.canvas.renderAll();
    }

    return (
        <div>
            Modify Image
            <div className="p-1 rounded-md" style={{ border: '1px solid grey' }}>
                <div className="flex flex-col">
                    <div className="flex flex-row p-2">
                        <div className="flex justify-center items-center">
                            Opacity
                        </div>
                        <div className="flex items-center ml-2" style={{ flexGrow: 1 }}>
                            <SettingSlider min={0} max={1} step={0.1}
                                onChange={handleOpacity}
                                initialValue={[0]} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}