const fonts = {
	default: [
		{ name: 'Arial', type: 'sans-serif', ref: 'default' },
		{ name: 'Arial Black', type: 'sans-serif', ref: 'default' },
		{ name: 'Verdana', type: 'sans-serif', ref: 'default' },
		{ name: 'Trebuchet MS', type: 'sans-serif', ref: 'default' },
		{ name: 'Tahoma', type: 'sans-serif', ref: 'default' },
		{ name: 'MS Sans Serif', type: 'sans-serif', ref: 'default' },
		{ name: 'Symbol', type: 'sans-serif', ref: 'default' },
		{ name: 'Times New Roman', type: 'serif', ref: 'default' },
		{ name: 'Book Antiqua', type: 'serif', ref: 'default' },
		{ name: 'Georgia', type: 'serif', ref: 'default' },
		{ name: 'Courier New', type: 'monospace', ref: 'default' },
		{ name: 'Comic Sans MS', type: 'cursive', ref: 'default' },
		{ name: 'Lucida Console', type: 'cursive', ref: 'default' },
		{ name: 'Impact', type: 'fantasy', ref: 'default' },
		{ name: 'Abril Fatface', type: 'display', ref: 'google' },
	]
};

export default {
	getFonts() {
		return fonts;
	},
};
