export const CHANGE_MAP_MODE = 'CHANGE_MAP_MODE';
export const CHANGE_WALLET_CONNECTED = 'CHANGE_WALLET_CONNECTED';
export const AFTER_LOGOUT = 'AFTER_LOGOUT';
export const SET_MARKER_SET = 'SET_MARKER_SET';
export const SET_PAYMENT_APPROVED = 'SET_PAYMENT_APPROVED';
export const SET_CURRENT_MARKER_IMAGE = 'SET_CURRENT_MARKER_IMAGE';
export const UPDATE_CURRENT_MARKER_INFO = 'UPDATE_CURRENT_MARKER_INFO';

export const changeMapMode = (newMode) => {
    return async dispath => {
        dispath({
            type: CHANGE_MAP_MODE,
            pinModeActiveNEW: newMode
        });
    };
};

export const changeWalletConnected = (newStatus) => {
    return async dispath => {
        dispath({
            type: CHANGE_WALLET_CONNECTED,
            walletConnectedNEW: newStatus
        });
    };
};

export const afterLogout = () => {
    return async dispath => {
        dispath({
            type: AFTER_LOGOUT
        });
    };
};

export const setMarkerSet = (newStatus) => {
    return async dispath => {
        dispath({
            type: SET_MARKER_SET,
            isMarkerSetNEW: newStatus
        });
    };
};

export const setPaymentApproved = (newStatus) => {
    return async dispath => {
        dispath({
            type: SET_PAYMENT_APPROVED,
            paymentApprovedNEW: newStatus
        });
    };
};

export const setCurrentMarkerImage = (newImage) => {
    return async dispath => {
        dispath({
            type: SET_CURRENT_MARKER_IMAGE,
            currentMarkerImageNEW: newImage
        });
    };
};

export const updateCurrentMarkerInfo = (newPosition, newZoom, newName, newMessage) => {
    return async dispath => {
        dispath({
            type: UPDATE_CURRENT_MARKER_INFO,
            positionNEW: newPosition,
            zoomNEW: newZoom,
            nameNEW: newName,
            messageNEW: newMessage
        });
    };
};