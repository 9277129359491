/*
 * 0xa869: avaxtestnet
 * 0x61: bsctestnet
 * 0x4: rinkeby
 * 0x13881: polygontestnet
 *
 * 0xa86a: avax
 * 0x38: bsc
 * 0x1: eth
 * 0x89: polygon
 *
 */

export const PINYWORLD_NFT_ADDRESS = {
  "0xa869": "0x6eAE1F4e83DAE639aE748b9A73011F478D763D9e",
  "0x61": "0x338e0625911Ef5D622E6413660F82E294831e568",
  "0x4": "0x339559C86dE3c2d9609A225164d4cff44689E049",
  "0x13881": "0x862f2Dec23988354187D2AAe058172A98f655417",
  "0xa86a": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x38": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x1": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
  "0x89": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
};

export const PINYWORLD_EVENT_ADDRESS = {
  "0xa869": "0x6470C00e4b587eC2213Bdcd0fb27404767ea8194",
  "0x61": "0x30b046E0A349e88b91848030E041dd1781de546d",
  "0x4": "0xbEc1211de3f7B829fdE40AB3257E3c24e5f186ab",
  "0x13881": "0x52A91ef380493eE4791d2cc501D7363Ac7472a47",
  "0xa86a": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x38": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x1": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
  "0x89": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
};

export const PINYWORLD_MINT_LISTENER_ADDRESS = {
  "0xa869": "0x5Ee81479431e984628108aCDF1F11862062C7940",
  "0x61": "0x1d2e94461Dfd5b15A4Ddc81B15CDAC834f2E172A",
  "0x4": "0xEf74d04f526BBf96260EF9B27b695f2aFeD80B68",
  "0x13881": "0x6851C04d1afd26b0DE512Aa1955eB9aEc670E00B",
  "0xa86a": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x38": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x1": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
  "0x89": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
};

export const PINYWORLD_EVENT_LISTENER_ADDRESS = {
  "0xa869": "0xC1C167CdE63EB49D522Ed3b603C052966A19799e",
  "0x61": "0x24260f229C150344A988D9d755dC974c2ac38FDC",
  "0x4": "0xE695f0eB8EaF174210acAB0c8Ca2D2bf9154b560",
  "0x13881": "0xa4116d0376C82826f651d970dD612D8f0a1f9982",
  "0xa86a": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x38": "0xBD8333e8a1DFcB7c8b13F24340962996DAA36d82",
  "0x1": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
  "0x89": "0x71D8F39fec3A4F85979A41edF70417343ea061E9",
};

export const PINYWORLD_TOKEN_ADDRESS_USDT = {
  "0xa869": "0xa4116d0376C82826f651d970dD612D8f0a1f9982",
  "0x61": "0x8f9B699203c06fdE87c3BaE6AD10C2DFA5348fBe",
  "0x4": "0xcB42331F27F677f0A0023F4464312B6aa12a07Ac",
  "0x13881": "0x970a7d0B5859493e81413d143693060846215A3F",
  "0xa86a": "0xc7198437980c041c805a1edcba50c1ce5db95118",
  "0x38": "0x55d398326f99059fF775485246999027B3197955",
  "0x1": "0xdac17f958d2ee523a2206206994597c13d831ec7",
  "0x89": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
};

export const getPinyWorldNftAddress = (chainId) => {
  return PINYWORLD_NFT_ADDRESS[chainId];
};

export const getPinyWorldEventAddress = (chainId) => {
  return PINYWORLD_EVENT_ADDRESS[chainId];
};

export const getPinyWorldMintListenerAddress = (chainId) => {
  return PINYWORLD_MINT_LISTENER_ADDRESS[chainId];
};

export const getPinyWorldEventListenerAddress = (chainId) => {
  return PINYWORLD_EVENT_LISTENER_ADDRESS[chainId];
};

export const getUSDTTokenAddress = (chainId) => {
  return PINYWORLD_TOKEN_ADDRESS_USDT[chainId];
};