import React, { useRef } from "react";
import Popover from "@material-tailwind/react/Popover";
import PopoverBody from "@material-tailwind/react/PopoverBody";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";
import 'emoji-mart/css/emoji-mart.css'
import data from 'emoji-mart/data/apple.json'
import { NimblePicker } from 'emoji-mart'

export default function SettingEmojiPicker(props) {

    const buttonRef = useRef();

    return (
        <>
            <button
                ref={buttonRef}
                disabled={false}
                className={props.enButtonClassNames + "w-8 h-10 ml-1"}
            >
                <i className="far fa-grin fa-lg" />
            </button>

            <Popover placement="bottom" ref={buttonRef}>
                <PopoverContainer>
                    <PopoverBody>
                        <div>
                            <NimblePicker
                                autoFocus={true}
                                color="hsl(0,0%,20%)"
                                theme="light"
                                sheetSize={16}
                                showPreview={false}
                                title=" "
                                emoji=" "
                                native={true}
                                set="apple"
                                data={data}
                                onSelect={(emoji) => {
                                    props.addEmoji(emoji.native);
                                }}
                            />
                        </div>
                    </PopoverBody>
                </PopoverContainer>
            </Popover>
        </>
    );
}