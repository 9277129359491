import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/mainpage/Header";
import DefaultNavbar from "components/DefaultNavbar";
import { Steps, Spin, Skeleton } from "antd";
import "antd/dist/antd.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "@material-tailwind/react";
import Radio from "@material-tailwind/react/radio";
import { getChain, useMoralis } from "react-moralis";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import mintlistenerabi from "contracts/abi/PinyWorldMintListener.json";
import erc20abi from "contracts/abi/ERC20.json";
import { useCallback, useEffect, useState } from "react";
import { tokenValue } from "helpers/formatters";
import { SyncOutlined } from "@ant-design/icons/lib/icons";
import { toTokenValue } from "helpers/formatters";
import { getPinyWorldEventAddress } from "constant/ContractAddress";
import { getPinyWorldEventListenerAddress } from "constant/ContractAddress";
import { ZERO_ADDRESS } from "helpers/formatters";
import { getCoinMetadata } from "helpers/coins";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PINYWORLD_API_END_POINT } from "constant";
import axios from "axios";
import GoogleMap from "components/GoogleMap";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Marker } from "components/mainpage/Content";
import { getChainIdByNetwork } from "helpers/networks";
import { getPinyWorldMintListenerAddress } from "constant/ContractAddress";

const { Step } = Steps;

export default function EventHome() {
  let { eventId } = useParams();
  const { Moralis, isWeb3Enabled, account, chainId } = useMoralis();

  const [showInvalidNetwork, setShowInvalidNetwork] = useState(false);

  const [event, setEvent] = useState(null);
  const [eventMarkerImageURIList, setEventMarkerImageURIList] = useState(null);
  const [eventCurrentMintCount, setEventCurrentMintCount] = useState(null);

  const [queryingEventMarker, setQueryingEventMarker] = useState(false);
  const [queryMarkerByAreaTimeout, setQueryMarkerByAreaTimeout] = useState();
  const [eventMarkerList, setEventMarkerList] = useState(null);
  const [mapZoom, setMapZoom] = useState(3);

  const [selectedMarkerURI, setSelectedMarkerURI] = useState("");
  const [pinModeActive, setPinModeActive] = useState(false);
  const [markerName, setMarkerName] = useState("");
  const [markerMessage, setMarkerMessage] = useState("");
  const [marker, setMarker] = useState();
  const [currentMarkerImage, setCurrentMarkerImage] = useState("");
  const [showMintMarkerModal, setShowMintMarkerModal] = useState(false);

  const [mintPaymentSettingList, setMintPaymentSettingList] = useState(null);
  const [selectedMintPaymentIndex, setSelectedMintPaymentIndex] = useState(null);
  const [mintPaymentMethod, setMintPaymentMethod] = useState(null);
  const [checkingMintPaymentApproval, setCheckingMintPaymentApproval] = useState(false);
  const [approvingMintPayment, setApprovingMintPayment] = useState(false);
  const [mintPaymentApproved, setMintPaymentApproved] = useState(false);
  const [insufficientMintBalance, setInsufficientMintBalance] = useState(false);

  const [eventPaymentSettingList, setEventPaymentSettingList] = useState(null);
  const [selectedEventPaymentIndex, setSelectedEventPaymentIndex] = useState(null);
  const [eventPaymentMethod, setEventPaymentMethod] = useState(null);
  const [checkingEventPaymentApproval, setCheckingEventPaymentApproval] = useState(false);
  const [approvingEventPayment, setApprovingEventPayment] = useState(false);
  const [eventPaymentApproved, setEventPaymentApproved] = useState(false);
  const [insufficientEventBalance, setInsufficientEventBalance] = useState(false);

  const [mintingMarker, setMintingMarker] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isNetworkValid = () => {
    if (!event) {
      return true;
    }

    if (getChainIdByNetwork(event.network) !== chainId) {
      setShowInvalidNetwork(true);
      return false;
    }

    return true;
  };

  const onMintPaymentMethodChange = (event) => {
    if (
      !mintPaymentSettingList ||
      event.target.value > mintPaymentSettingList.length
    ) {
      return;
    }

    setSelectedMintPaymentIndex(parseInt(event.target.value));
  };

  const onEventPaymentMethodChange = (event) => {
    if (
      !eventPaymentSettingList ||
      event.target.value > eventPaymentSettingList.length
    ) {
      return;
    }

    setSelectedEventPaymentIndex(parseInt(event.target.value));
  };

  const onSelectMintPaymentNextButtonClick = () => {
    if (
      !mintPaymentSettingList ||
      (selectedMintPaymentIndex !== 0 && !selectedMintPaymentIndex)
    ) {
      return;
    }

    setMintPaymentMethod(mintPaymentSettingList[selectedMintPaymentIndex]);
  };

  const onSelectEventPaymentNextButtonClick = () => {
    if (
      !eventPaymentSettingList ||
      (selectedEventPaymentIndex !== 0 && !selectedEventPaymentIndex)
    ) {
      return;
    }

    setEventPaymentMethod(eventPaymentSettingList[selectedEventPaymentIndex]);
  };

  const onInsufficientMintBalanceBackButtonClick = () => {
    if (mintPaymentSettingList && mintPaymentSettingList.length > 1) {
      setMintPaymentMethod(null);
      setMintPaymentApproved(false);
      setInsufficientMintBalance(false);
    }
  };

  const onApproveMintPaymentBackButtonClick = () => {
    if (mintPaymentSettingList && mintPaymentSettingList.length > 1) {
      setMintPaymentMethod(null);
      setMintPaymentApproved(false);
      setInsufficientMintBalance(false);
    }

    if (eventPaymentSettingList && eventPaymentSettingList.length > 1) {
      setEventPaymentMethod(null);
      setEventPaymentApproved(false);
      setInsufficientEventBalance(false);
    }
  };

  const onInsufficientEventBalanceBackButtonClick = () => {
    if (eventPaymentSettingList && eventPaymentSettingList.length > 1) {
      setEventPaymentMethod(null);
      setEventPaymentApproved(false);
      setInsufficientEventBalance(false);
    }
  };

  const onApproveEventPaymentBackButtonClick = () => {
    if (mintPaymentSettingList && mintPaymentSettingList.length > 1) {
      setMintPaymentMethod(null);
      setMintPaymentApproved(false);
      setInsufficientMintBalance(false);
    }

    if (eventPaymentSettingList && eventPaymentSettingList.length > 1) {
      setEventPaymentMethod(null);
      setEventPaymentApproved(false);
      setInsufficientEventBalance(false);
    }
  };

  const onConfirmMintBackButtonClick = () => {
    if (mintPaymentSettingList.length > 1) {
      setInsufficientMintBalance(false);
      setMintPaymentMethod(null);
      setMintPaymentApproved(false);
    }

    if (eventPaymentSettingList.length > 1) {
      setInsufficientEventBalance(false);
      setEventPaymentMethod(null);
      setEventPaymentApproved(false);
    }
  };

  const fetchEvent = useCallback(async () => {
    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT + "event/" + eventId
      );

      const respEvent = response.data.dto;

      const eventChain = getChainIdByNetwork(respEvent.network);

      let options = {
        chain: eventChain,
        address: getPinyWorldEventAddress(eventChain),
        function_name: "getEventMarkerImageURIList",
        abi: eventabi,
        params: { _eventId: respEvent.eventId },
      };

      setEventMarkerImageURIList(
        await Moralis.Web3API.native.runContractFunction(options)
      );

      options = {
        chain: eventChain,
        address: getPinyWorldEventAddress(eventChain),
        function_name: "getEventCurrentMintCount",
        abi: eventabi,
        params: { _eventId: respEvent.eventId },
      };

      setEventCurrentMintCount(
        await Moralis.Web3API.native.runContractFunction(options)
      );

      setEvent(respEvent);
    } catch (err) {
      console.error(err);
    }
  }, [Moralis.Web3API.native, eventId]);

  const fetchMintPaymentSettingLists = useCallback(async () => {
    if (!isWeb3Enabled || !event) {
      return;
    }

    const eventChain = getChainIdByNetwork(event.network);

    const options = {
      chain: eventChain,
      address: getPinyWorldMintListenerAddress(eventChain),
      function_name: "getPaymentSettingList",
      abi: mintlistenerabi,
      params: { _eventId: event.eventId },
    };

    const paymentSettingList = await Moralis.Web3API.native.runContractFunction(
      options
    );

    setMintPaymentSettingList(null);

    const paymentSettingListWithTokenMetadata = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = getCoinMetadata(eventChain);

        if (ps[0] !== ZERO_ADDRESS) {
          tokenMetadata = (
            await Moralis.Web3API.token.getTokenMetadata({
              chain: eventChain,
              addresses: [ps[0]],
            })
          )[0];
        }

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        const balanceAmount = tokenValue(ps[1][3], tokenMetadata.decimals);

        if (transferAmount > 0) {
          paymentSettingListWithTokenMetadata.push({
            token: tokenMetadata,
            type: 1,
            amount: transferAmount,
          });
        }

        if (balanceAmount > 0) {
          paymentSettingListWithTokenMetadata.push({
            token: tokenMetadata,
            type: 0,
            amount: balanceAmount,
          });
        }
      })
    );

    setMintPaymentSettingList(paymentSettingListWithTokenMetadata);
  }, [Moralis.Web3API.native, Moralis.Web3API.token, event, isWeb3Enabled]);

  const fetchEventPaymentSettingLists = useCallback(async () => {
    if (!isWeb3Enabled || !event) {
      return;
    }

    const eventChain = getChainIdByNetwork(event.network);

    const options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      function_name: "getEventPaymentSettingList",
      abi: eventabi,
      params: { _eventId: event.eventId },
    };

    const paymentSettingList = await Moralis.Web3API.native.runContractFunction(
      options
    );

    setEventPaymentSettingList(null);

    const paymentSettingListWithTokenMetadata = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = getCoinMetadata(eventChain);

        if (ps[0] !== ZERO_ADDRESS) {
          tokenMetadata = (
            await Moralis.Web3API.token.getTokenMetadata({
              chain: eventChain,
              addresses: [ps[0]],
            })
          )[0];
        }

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        const balanceAmount = tokenValue(ps[1][3], tokenMetadata.decimals);

        if (transferAmount > 0) {
          paymentSettingListWithTokenMetadata.push({
            token: tokenMetadata,
            type: 1,
            amount: transferAmount,
          });
        }

        if (balanceAmount > 0) {
          paymentSettingListWithTokenMetadata.push({
            token: tokenMetadata,
            type: 0,
            amount: balanceAmount,
          });
        }
      })
    );

    setEventPaymentSettingList(paymentSettingListWithTokenMetadata);
  }, [Moralis.Web3API.native, Moralis.Web3API.token, event, isWeb3Enabled]);

  const checkMintPaymentApproval = useCallback(async () => {
    if (!mintPaymentMethod) {
      return;
    }

    setCheckingMintPaymentApproval(true);

    if (mintPaymentMethod.type === 1) {
      if (mintPaymentMethod.token.address !== ZERO_ADDRESS) {
        let options = {
          contractAddress: mintPaymentMethod.token.address,
          functionName: "allowance",
          abi: erc20abi,
          params: {
            owner: account,
            spender: getPinyWorldMintListenerAddress(chainId),
          },
        };

        const allowance = tokenValue(
          await Moralis.executeFunction(options),
          mintPaymentMethod.token.decimals
        );

        if (allowance >= mintPaymentMethod.amount) {
          setMintPaymentApproved(true);
        }

        options = {
          contractAddress: mintPaymentMethod.token.address,
          functionName: "balanceOf",
          abi: erc20abi,
          params: {
            account: account,
          },
        };

        const balance = tokenValue(
          await Moralis.executeFunction(options),
          mintPaymentMethod.token.decimals
        );

        if (balance < mintPaymentMethod.amount) {
          setInsufficientMintBalance(true);
        }
      } else if (mintPaymentMethod.token.address === ZERO_ADDRESS) {
        setMintPaymentApproved(true);

        const options = { chain: chainId };
        const balance = tokenValue(
          (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
          mintPaymentMethod.token.decimals
        );

        if (balance < mintPaymentMethod.amount) {
          setInsufficientMintBalance(true);
        }
      }
    }

    setCheckingMintPaymentApproval(false);
  }, [Moralis, account, chainId, mintPaymentMethod]);

  const checkEventPaymentApproval = useCallback(async () => {
    if (!eventPaymentMethod) {
      return;
    }

    setCheckingEventPaymentApproval(true);

    if (eventPaymentMethod.type === 1) {
      if (eventPaymentMethod.token.address !== ZERO_ADDRESS) {
        let options = {
          contractAddress: eventPaymentMethod.token.address,
          functionName: "allowance",
          abi: erc20abi,
          params: {
            owner: account,
            spender: getPinyWorldEventListenerAddress(chainId),
          },
        };

        const allowance = tokenValue(
          await Moralis.executeFunction(options),
          eventPaymentMethod.token.decimals
        );

        if (allowance >= eventPaymentMethod.amount) {
          setEventPaymentApproved(true);
        }

        options = {
          contractAddress: eventPaymentMethod.token.address,
          functionName: "balanceOf",
          abi: erc20abi,
          params: {
            account: account,
          },
        };

        const balance = tokenValue(
          await Moralis.executeFunction(options),
          eventPaymentMethod.token.decimals
        );

        if (balance < eventPaymentMethod.amount) {
          setInsufficientEventBalance(true);
        }
      } else if (eventPaymentMethod.token.address === ZERO_ADDRESS) {
        setEventPaymentApproved(true);

        const options = { chain: chainId };
        const balance = tokenValue(
          (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
          eventPaymentMethod.token.decimals
        );

        if (balance < eventPaymentMethod.amount) {
          setInsufficientEventBalance(true);
        }
      }
    }

    setCheckingEventPaymentApproval(false);
  }, [Moralis, account, chainId, eventPaymentMethod]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  useEffect(() => {
    fetchMintPaymentSettingLists();
    fetchEventPaymentSettingLists();
  }, [fetchMintPaymentSettingLists, fetchEventPaymentSettingLists]);

  useEffect(() => {
    checkMintPaymentApproval();
  }, [checkMintPaymentApproval]);

  useEffect(() => {
    checkEventPaymentApproval();
  }, [checkEventPaymentApproval]);

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  const onMapBoundsChanged = (map) => {
    if (!map) {
      return;
    }

    const queryMarkerByArea = (map) => {
      if (!event) {
        setQueryMarkerByAreaTimeout(
          setTimeout(() => {
            queryMarkerByArea(map);
          }, 2000)
        );

        return;
      }

      if (queryingEventMarker) {
        return;
      }

      axios
        .post(PINYWORLD_API_END_POINT + "marker/query/criteria", {
          criteria: {
            topRight: {
              longitude: map.getBounds().getNorthEast().lng(),
              latitude: map.getBounds().getNorthEast().lat(),
            },
            bottomLeft: {
              longitude: map.getBounds().getSouthWest().lng(),
              latitude: map.getBounds().getSouthWest().lat(),
            },
            eventIdList: [event.eventId],
            networkList: [event.network],
          },
        })
        .then((response) => {
          setQueryingEventMarker(false);

          setEventMarkerList(
            response.data.map((marker) => (
              <Marker
                key={marker.id}
                icon={{
                  url: "https://ipfs.io/ipfs/" + marker.imageUrl,
                  scaledSize: { width: 45, height: 70 },
                }}
                position={{
                  lng: marker.coordinate.longitude,
                  lat: marker.coordinate.latitude,
                }}
              />
            ))
          );
        });
    };

    if (queryMarkerByAreaTimeout) {
      clearTimeout(queryMarkerByAreaTimeout);
    } else {
      setQueryMarkerByAreaTimeout(
        setTimeout(() => {
          queryMarkerByArea(map);
        }, 2000)
      );
    }
  };

  const onMapClick = (event) => {
    if (!selectedMarkerURI) {
      return;
    }

    setMarker(
      <Marker
        position={event.latLng}
        icon={{
          url: `https://ipfs.io/ipfs/${selectedMarkerURI}`,
          scaledSize: { width: 45, height: 70 },
        }}
      />
    );

    setCurrentMarkerImage("https://ipfs.io/ipfs/" + selectedMarkerURI);
  };

  const triggerSetMarkerEditModal = () => {};

  const onZoomChanged = (map) => {
    setMapZoom(map.getZoom());
  };

  const onMarkerSelect = (index) => {
    setPinModeActive(true);
    setSelectedMarkerURI(eventMarkerImageURIList[index]);
    setCurrentMarkerImage(
      "https://ipfs.io/ipfs/" + eventMarkerImageURIList[index]
    );

    if (marker) {
      setMarker(
        <Marker
          position={marker.props.position}
          icon={{
            url: `https://ipfs.io/ipfs/${eventMarkerImageURIList[index]}`,
            scaledSize: { width: 45, height: 70 },
          }}
        />
      );
    }
  };

  const onMarkerNameChange = (event) => {
    setMarkerName(event.target.value);
  };

  const onMarkerMessageChange = (event) => {
    setMarkerMessage(event.target.value);
  };

  const onSubmitButtonClick = () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!mintPaymentSettingList || !eventPaymentSettingList) {
      return;
    }

    if (mintPaymentSettingList.length === 1) {
      setMintPaymentMethod(mintPaymentSettingList[0]);
    }

    if (eventPaymentSettingList.length === 1) {
      setEventPaymentMethod(eventPaymentSettingList[0]);
    }

    setShowMintMarkerModal(true);
    setErrorMessage("");
  };

  const cancelMint = () => {
    setPinModeActive(false);
    setSelectedMarkerURI("");
    setMarker(null);
    setCurrentMarkerImage(null);
  };

  const approveMintPaymentMethod = async () => {
    if (!isNetworkValid()) {
      return;
    }

    setApprovingMintPayment(true);

    const options = {
      contractAddress: mintPaymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldMintListenerAddress(chainId),
        amount: toTokenValue(
          mintPaymentMethod.amount,
          mintPaymentMethod.token.decimals
        ),
      },
    };

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err);
      return;
    } finally {
      setApprovingMintPayment(false);
    }

    setMintPaymentApproved(true);
  };

  const approveEventPaymentMethod = async () => {
    if (!isNetworkValid()) {
      return;
    }

    setApprovingEventPayment(true);

    const options = {
      contractAddress: eventPaymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldEventListenerAddress(chainId),
        amount: toTokenValue(
          eventPaymentMethod.amount,
          eventPaymentMethod.token.decimals
        ),
      },
    };

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err);
      return;
    } finally {
      setApprovingEventPayment(false);
    }

    setEventPaymentApproved(true);
  };

  const submitMintMarker = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!mintPaymentSettingList || !eventPaymentSettingList) {
      return;
    }

    if (
      (mintPaymentSettingList.length > 0 && !mintPaymentMethod) ||
      (eventPaymentSettingList.length > 0 && !eventPaymentMethod)
    ) {
      return;
    }

    setMintingMarker(true);

    const metadata = {
      name: markerName,
      description: markerMessage,
      image: "ipfs://" + selectedMarkerURI,
      attributes: [
        { trait_type: "Latitude", value: "" + marker.props.position.lat() },
        { trait_type: "Longitude", value: "" + marker.props.position.lng() },
        { trait_type: "Map Zoom", value: "" + mapZoom },
      ],
    };

    const metadataFile = new Moralis.File("metadata.json", {
      base64: btoa(JSON.stringify(metadata)),
    });

    await metadataFile.saveIPFS();

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "mintMarker",
      abi: eventabi,
      params: {
        _eventId: event.eventId,
        _markerProps: [
          markerName,
          markerMessage,
          "" + marker.props.position.lat(),
          "" + marker.props.position.lng(),
          mapZoom,
          selectedMarkerURI,
        ],
        _tokenURI: metadataFile.hash(),
        _mintPaymentParams: mintPaymentMethod
          ? [mintPaymentMethod.token.address, mintPaymentMethod.type]
          : [ZERO_ADDRESS, 0],
        _eventPaymentParams: eventPaymentMethod
          ? [eventPaymentMethod.token.address, eventPaymentMethod.type]
          : [ZERO_ADDRESS, 0],
      },
    };

    let msgValue = 0;

    if (
      mintPaymentMethod.token.address === ZERO_ADDRESS &&
      mintPaymentMethod.type === 1
    ) {
      msgValue += mintPaymentMethod.amount;
    }

    if (
      eventPaymentMethod.token.address === ZERO_ADDRESS &&
      eventPaymentMethod.type === 1
    ) {
      msgValue += eventPaymentMethod.amount;
    }

    if (msgValue > 0) {
      options["msgValue"] = toTokenValue(
        msgValue,
        getCoinMetadata(chainId).decimals
      );
    }

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setMintingMarker(false);
    }
  };

  const computeMintMarkerStep = () => {
    if (!mintPaymentSettingList || !eventPaymentSettingList) {
      return 0;
    }

    if (!mintPaymentSettingList.length && !eventPaymentSettingList.length) {
      return 0;
    }

    if (
      mintPaymentSettingList.length > 1 ||
      eventPaymentSettingList.length > 1
    ) {
      if (!mintPaymentMethod || !eventPaymentMethod) {
        return 0;
      }

      if (
        !mintPaymentApproved ||
        checkingMintPaymentApproval ||
        insufficientMintBalance ||
        !eventPaymentApproved ||
        checkingEventPaymentApproval ||
        insufficientEventBalance
      ) {
        return 1;
      }

      return 2;
    }

    if (!mintPaymentApproved || !eventPaymentApproved) {
      return 0;
    }

    return 1;
  };

  const computeMintMarkerStepContent = () => {
    if (!mintPaymentSettingList || !eventPaymentSettingList) {
      return null;
    }

    if (
      mintPaymentSettingList.length > 1 ||
      eventPaymentSettingList.length > 1
    ) {
      return (
        <Steps current={computeMintMarkerStep()}>
          <Step title="Select payment method" />
          <Step title="Approve payment method" />
          <Step title="Confirm" />
        </Steps>
      );
    }

    return (
      <Steps current={computeMintMarkerStep()}>
        <Step title="Approve payment method" />
        <Step title="Confirm" />
      </Steps>
    );
  };

  const fetchingPaymentMethodContent = () => {
    return <div>Fetching payment methods...</div>;
  };

  const selectMintPaymentMethodContent = () => {
    return (
      <div>
        <div>Please select payment method for minting marker</div>
        <div>
          {mintPaymentSettingList.map((ps, index) => {
            return (
              <Radio
                onChange={onMintPaymentMethodChange}
                key={index}
                value={index}
                id={`paymentMethod${index}`}
                color="lightBlue"
                text={`${ps.amount} ${ps.token.symbol} ${
                  ps.type === 1 ? "Transfer" : "Bakiye"
                }`}
                name="paymentMethod"
                checked={selectedMintPaymentIndex === index}
              />
            );
          })}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button onClick={onSelectMintPaymentNextButtonClick}>NEXT</Button>
        </div>
      </div>
    );
  };

  const insufficientMintBalanceContent = () => {
    if (!mintPaymentMethod) {
      return <Spin />;
    }

    return (
      <div>
        <div>INSUFFICIENT BALANCE</div>
        <div>Marker mint Fee</div>
        <div>
          {mintPaymentMethod.amount} {mintPaymentMethod.token.name}
        </div>
        {mintPaymentSettingList && mintPaymentSettingList.length > 1 ? (
          <Button onClick={onInsufficientMintBalanceBackButtonClick}>
            BACK
          </Button>
        ) : null}
      </div>
    );
  };

  const approveMintPaymentContent = () => {
    if (mintPaymentMethod) {
      return (
        <div>
          <div>Approve Mint Fee</div>
          <div>
            {mintPaymentMethod.amount} {mintPaymentMethod.token.symbol}
          </div>
          <div>
            {insufficientMintBalance ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {(mintPaymentSettingList &&
                  mintPaymentSettingList.length > 1) ||
                (eventPaymentSettingList &&
                  eventPaymentSettingList.length > 1) ? (
                  <Button
                    disabled={
                      checkingMintPaymentApproval || approvingMintPayment
                    }
                    onClick={() => onApproveMintPaymentBackButtonClick()}
                  >
                    BACK
                  </Button>
                ) : null}
                <Button
                  disabled={checkingMintPaymentApproval || approvingMintPayment}
                  onClick={() => approveMintPaymentMethod()}
                >
                  APPROVE{" "}
                  {checkingMintPaymentApproval || approvingMintPayment ? (
                    <SyncOutlined spin />
                  ) : null}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const selectEventPaymentMethodContent = () => {
    return (
      <div>
        <div>Please select payment method for event</div>
        <div>
          {eventPaymentSettingList.map((ps, index) => {
            return (
              <Radio
                onChange={onEventPaymentMethodChange}
                key={index}
                value={index}
                id={`paymentMethod${index}`}
                color="lightBlue"
                text={`${ps.amount} ${ps.token.symbol} ${
                  ps.type === 1 ? "Transfer" : "Bakiye"
                }`}
                name="paymentMethod"
                checked={selectedEventPaymentIndex === index}
              />
            );
          })}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button onClick={onSelectEventPaymentNextButtonClick}>NEXT</Button>
        </div>
      </div>
    );
  };

  const insufficientEventBalanceContent = () => {
    if (!eventPaymentMethod) {
      return <Spin />;
    }

    return (
      <div>
        <div>INSUFFICIENT BALANCE</div>
        <div>Event Fee</div>
        <div>
          {eventPaymentMethod.amount} {eventPaymentMethod.token.name}
        </div>
        {eventPaymentSettingList && eventPaymentSettingList.length > 1 ? (
          <Button onClick={onInsufficientEventBalanceBackButtonClick}>
            BACK
          </Button>
        ) : null}
      </div>
    );
  };

  const approveEventPaymentContent = () => {
    if (mintPaymentMethod) {
      return (
        <div>
          <div>Approve Event Fee</div>
          <div>
            {eventPaymentMethod.amount} {eventPaymentMethod.token.symbol}
          </div>
          <div>
            {insufficientEventBalance ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {eventPaymentSettingList &&
                eventPaymentSettingList.length > 1 ? (
                  <Button
                    disabled={
                      checkingEventPaymentApproval || approvingEventPayment
                    }
                    onClick={() => onApproveEventPaymentBackButtonClick()}
                  >
                    BACK
                  </Button>
                ) : null}
                <Button
                  disabled={
                    checkingEventPaymentApproval || approvingEventPayment
                  }
                  onClick={() => approveEventPaymentMethod()}
                >
                  APPROVE{" "}
                  {checkingEventPaymentApproval || approvingEventPayment ? (
                    <SyncOutlined spin />
                  ) : null}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const confirmMintMarkerContent = () => {
    return (
      <div>
        {mintPaymentSettingList.length > 0 ? (
          <div>
            <div>Mint Fee</div>
            <div>
              {mintPaymentMethod.amount} {mintPaymentMethod.token.name}
            </div>
          </div>
        ) : null}

        {eventPaymentSettingList.length > 0 ? (
          <div>
            <div>Event Fee</div>
            <div>
              {eventPaymentMethod.amount} {eventPaymentMethod.token.name}
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {mintPaymentSettingList.length > 1 ||
          eventPaymentSettingList.length > 1 ? (
            <Button onClick={() => onConfirmMintBackButtonClick()}>BACK</Button>
          ) : null}
          <Button disabled={mintingMarker} onClick={() => submitMintMarker()}>
            MINT {mintingMarker ? <SyncOutlined spin /> : null}
          </Button>
        </div>
      </div>
    );
  };

  const errorContent = () => {
    return (
      <div>
        <div>{errorMessage}</div>
      </div>
    );
  };

  const computeConfirmDialogContent = () => {
    if (errorMessage) {
      return errorContent();
    }

    if (!mintPaymentSettingList || !eventPaymentSettingList) {
      return fetchingPaymentMethodContent();
    }

    if (mintPaymentSettingList.length > 1) {
      if (!mintPaymentMethod) {
        return selectMintPaymentMethodContent();
      }
    }

    if (eventPaymentSettingList.length > 1) {
      if (!eventPaymentMethod) {
        return selectEventPaymentMethodContent();
      }
    }

    if (insufficientMintBalance) {
      return insufficientMintBalanceContent();
    }

    if (insufficientEventBalance) {
      return insufficientEventBalanceContent();
    }

    if (mintPaymentSettingList.length > 0) {
      if (!mintPaymentApproved) {
        return approveMintPaymentContent();
      }
    }

    if (eventPaymentSettingList.length > 0) {
      if (!eventPaymentApproved) {
        return approveEventPaymentContent();
      }
    }

    if (checkingMintPaymentApproval) {
      return approveMintPaymentContent();
    }

    if (checkingEventPaymentApproval) {
      return approveEventPaymentContent();
    }

    return confirmMintMarkerContent();
  };

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <section className="relative py-16 bg-gray-100">
          <div
            className="max-w-7xl px-4 mx-auto"
            style={{ marginTop: "-224px" }}
          >
            {event ? (
              <Card>
                <CardHeader color="purple" contentPosition="none">
                  <div className="w-full flex items-center justify-center">
                    <h2 className="text-white text-2xl">{event.name}</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {event.description}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      Max mint count {event.maxMintCount}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      Total mint count {eventCurrentMintCount}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "16px",
                        }}
                      >
                        Select a marker to pin on the map:
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {eventMarkerImageURIList.map((marker, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "60px",
                                marginRight: "8px",
                                cursor: "pointer",
                                border:
                                  selectedMarkerURI === marker
                                    ? "1px solid blue"
                                    : "",
                              }}
                              onClick={() => {
                                onMarkerSelect(index);
                              }}
                            >
                              <img
                                src={`https://ipfs.io/ipfs/${marker}`}
                                alt="Marker"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {selectedMarkerURI ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <div className="w-full font-light mt-5">
                        <Input
                          type="text"
                          color="purple"
                          placeholder="Marker Name"
                          value={markerName}
                          onChange={onMarkerNameChange}
                        />
                      </div>
                      <div className="w-full font-light mt-12">
                        <Textarea
                          rows="4"
                          size="3"
                          color="purple"
                          placeholder="Message to the World"
                          value={markerMessage}
                          onChange={onMarkerMessageChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "16px",
                          marginBottom: "16px",
                        }}
                      >
                        {marker ? (
                          <Button onClick={onSubmitButtonClick}>
                            PIN MARKER
                          </Button>
                        ) : (
                          <Button disabled={true}>
                            CLICK ON THE MAP TO PIN A MARKER
                          </Button>
                        )}
                        <Button onClick={cancelMint}>CANCEL</Button>
                      </div>
                    </div>
                  ) : null}

                  <div style={{ marginTop: "16px" }}>
                    <Wrapper
                      apiKey={"AIzaSyBtVHcUCL8ZPPzvsP23n3Mby9bwGcaRKUg"}
                      render={render}
                      language="en"
                    >
                      <GoogleMap
                        onClick={onMapClick}
                        onBoundsChanged={onMapBoundsChanged}
                        onZoomChanged={onZoomChanged}
                        pinModeActive={pinModeActive}
                        setMarkerEditModal={triggerSetMarkerEditModal}
                        currentMarkerImage={currentMarkerImage}
                        latitude={(null !== event ? event.mapCenter.latitude : undefined)}
                        longitude={(null !== event ? event.mapCenter.longitude : undefined)}
                        zoom={(null !== event ? event.mapZoom : undefined)}
                      >
                        {pinModeActive ? marker : eventMarkerList}
                      </GoogleMap>
                    </Wrapper>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card>
                <CardHeader color="purple" contentPosition="none">
                  <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Loading...</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <Skeleton active={true}></Skeleton>
                </CardBody>
              </Card>
            )}
          </div>

          <Modal
            size="regular"
            active={showInvalidNetwork}
            toggler={() => {
              setShowInvalidNetwork(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowInvalidNetwork(false);
              }}
            >
              Invalid Network
            </ModalHeader>
            <ModalBody>
              {showInvalidNetwork && event ? (
                <div>
                  <div>
                    Please connect to{" "}
                    {getChain(getChainIdByNetwork(event.network)).name}
                  </div>

                  <div>You are connected to {getChain(chainId).name}</div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>

          <Modal
            size="regular"
            active={showMintMarkerModal}
            toggler={() => {
              setShowMintMarkerModal(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowMintMarkerModal(false);
              }}
            >
              CONFIRM MINT MARKER
            </ModalHeader>
            <ModalBody>
              {showMintMarkerModal ? (
                <div>
                  <div>{computeMintMarkerStepContent()}</div>
                  <div style={{ marginTop: "16px" }}>
                    {computeConfirmDialogContent()}
                  </div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
