import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/mainpage/Header";
import Content from "components/mainpage/Content";
import Events from "components/mainpage/Events";

export default function MainPage() {
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <Content />
        <Events />
      </main>
      <DefaultFooter />
    </>
  );
}
