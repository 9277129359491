import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Label from "@material-tailwind/react/Label";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/mainpage/Header";
import DefaultNavbar from "components/DefaultNavbar";
import { Steps, Spin, Switch, Skeleton } from "antd";
import ModalMarkerEdit from "components/ModalMarkerEdit";
import "antd/dist/antd.css";
import { Button, Icon, Modal, ModalBody, ModalHeader, Tab, TabContent, TabItem, TabList, TabPane } from "@material-tailwind/react";
import Radio from "@material-tailwind/react/radio";
import { getChain, useMoralis, useMoralisCloudFunction } from "react-moralis";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import eventlistenerabi from "contracts/abi/PinyWorldEventListener.json";
import erc20abi from "contracts/abi/ERC20.json";
import { useCallback, useEffect, useState, useRef } from "react";
import { tokenValue } from "helpers/formatters";
import { SyncOutlined } from "@ant-design/icons/lib/icons";
import { toTokenValue } from "helpers/formatters";
import { useParams } from "react-router-dom";
import { PINYWORLD_API_END_POINT } from "constant";
import axios from "axios";
import { getChainIdByNetwork } from "helpers/networks";
import { getPinyWorldEventAddress } from "constant/ContractAddress";
import { ZERO_ADDRESS } from "helpers/formatters";
import { getCoinMetadata } from "helpers/coins";
import GoogleMapViewOnly from "components/GoogleMapViewOnly";
import { Wrapper } from "@googlemaps/react-wrapper";

export default function UpdateEvent() {
  const { Moralis, isWeb3Enabled, account, chainId } = useMoralis();

  let { eventId } = useParams();

  const [showInvalidNetwork, setShowInvalidNetwork] = useState(false);

  const [openTab, setOpenTab] = useState(1);
  const [event, setEvent] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const mapViewOnly = useRef(null);
  const [eventEnabled, setEventEnabled] = useState(false);
  const [customMarkerEnabled, setCustomMarkerEnabled] = useState(false);
  const [updatingEvent, setUpdatingEvent] = useState(false);

  const [showTransferEventModal, setShowTransferEventModal] = useState(false);
  const [eventTransferAddress, setEventTransferAddress] = useState("");
  const [transferringEvent, setTransferringEvent] = useState(false);

  const [eventPaymentSettingList, setEventPaymentSettingList] = useState(null);
  const [showAddPaymentSetting, setShowAddPaymentSetting] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [transferAmount, setTransferAmount] = useState(0);
  const [transferAddress, setTransferAddress] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [savingPaymentSetting, setSavingPaymentSetting] = useState(false);
  const [editingPaymentSetting, setEditingPaymentSetting] = useState(false);
  const [deletingPaymentSetting, setDeletingPaymentSetting] = useState(false);

  const [eventMarkerImageURIList, setEventMarkerImageURIList] = useState(null);
  const [showAddMarkerModal, setShowAddMarkerModal] = useState(false);
  const [eventMarkerToAdd, setEventMarkerToAdd] = useState(null);
  const [showAddMarkerConfirmModal, setShowAddMarkerConfirmModal] = useState(false);
  const [addingMarker, setAddingMarker] = useState(false);
  const [removeMarkerURI, setRemoveMarkerURI] = useState("");
  const [removingMarker, setRemovingMarker] = useState(false);

  const { fetch: cloudFunc_pinyWorldEventMapUpdate } = useMoralisCloudFunction("pinyWorldEventMapUpdate", {}, { autoFetch: false });

  const onEventEnabledChange = (checked) => {
    setEventEnabled(checked);
  };

  const onCustomMarkerEnabledChange = (checked) => {
    setCustomMarkerEnabled(checked);
  };

  const onEventTransferAddressChange = (event) => {
    setEventTransferAddress(event.target.value);
  };

  const onTokenAddressChange = (event) => {
    setTokenAddress(event.target.value);
  };

  const onTransferAmountChange = (event) => {
    setTransferAmount(event.target.value);
  };

  const onTransferAddressChange = (event) => {
    setTransferAddress(event.target.value);
  };

  const onBalanceAmountChange = (event) => {
    setBalanceAmount(event.target.value);
  };

  const fetchEvent = useCallback(async () => {
    if (!isWeb3Enabled || !account) {
      return;
    }

    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT + "event/" + eventId
      );

      setEvent(response.data.dto);
      setEventEnabled(response.data.dto.enabled);
      setCustomMarkerEnabled(response.data.dto.customMarkerEnabled);
    } catch (err) {
      console.error(err);
    }
  }, [account, eventId, isWeb3Enabled]);

  const fetchPaymentSettings = useCallback(async () => {
    if (!event) {
      return;
    }

    const eventChain = getChainIdByNetwork(event.network);

    const options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      function_name: "getEventPaymentSettingList",
      abi: eventabi,
      params: { _eventId: event.eventId },
    };

    const paymentSettingList = await Moralis.Web3API.native.runContractFunction(
      options
    );

    setEventPaymentSettingList(null);

    const paymentSettingListWithTokenMetadata = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = getCoinMetadata(eventChain);

        if (ps[0] !== ZERO_ADDRESS) {
          tokenMetadata = (
            await Moralis.Web3API.token.getTokenMetadata({
              chain: eventChain,
              addresses: [ps[0]],
            })
          )[0];
        }

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        const balanceAmount = tokenValue(ps[1][3], tokenMetadata.decimals);

        paymentSettingListWithTokenMetadata.push({
          token: tokenMetadata,
          transferAmount,
          transferAddress: ps[1][2],
          balanceAmount,
        });
      })
    );

    setEventPaymentSettingList(paymentSettingListWithTokenMetadata);
  }, [Moralis.Web3API.native, Moralis.Web3API.token, chainId, event]);

  const fetchEventMarkerURIList = useCallback(async () => {
    if (!event) {
      return;
    }

    const eventChain = getChainIdByNetwork(event.network);

    const options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      function_name: "getEventMarkerImageURIList",
      abi: eventabi,
      params: { _eventId: event.eventId },
    };

    setEventMarkerImageURIList(
      await Moralis.Web3API.native.runContractFunction(options)
    );
  }, [Moralis.Web3API.native, event]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  useEffect(() => {
    fetchPaymentSettings();
  }, [fetchPaymentSettings]);

  useEffect(() => {
    fetchEventMarkerURIList();
  }, [fetchEventMarkerURIList]);

  const isNetworkValid = () => {
    if (!event) {
      return true;
    }

    if (getChainIdByNetwork(event.network) !== chainId) {
      setShowInvalidNetwork(true);
      return false;
    }

    return true;
  };

  const updateEvent = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    let updateStarted = false;

    if (event.mapZoom !== mapViewOnly.current.getZoom()
      || event.mapCenter.longitude !== mapViewOnly.current.getCenter().lng()
      || event.mapCenter.latitude !== mapViewOnly.current.getCenter().lat()) {

      console.log("Off-chain update triggering");
      setUpdatingEvent(true);
      updateStarted = true;

      const option_pinyWorldEventMapUpdate = {
        network: event.network,
        eventId: event.eventId,
        zoom: mapViewOnly.current.getZoom(),
        center: {
          "longitude": mapViewOnly.current.getCenter().lng(),
          "latitude": mapViewOnly.current.getCenter().lat()
        }
      };

      await cloudFunc_pinyWorldEventMapUpdate({
        params: option_pinyWorldEventMapUpdate,
        onSuccess: (data) => { },
      });

    }

    if (event.enabled !== eventEnabled
      || event.customMarkerEnabled !== customMarkerEnabled) {

      console.log("On-chain update triggering");
      setUpdatingEvent(true);
      updateStarted = true;

      const options = {
        contractAddress: getPinyWorldEventAddress(chainId),
        functionName: "updateEvent",
        abi: eventabi,
        params: {
          _eventUpdateParams: [
            event.eventId,
            eventEnabled,
            customMarkerEnabled,
            mapViewOnly.current.getCenter().lat() + '',
            mapViewOnly.current.getCenter().lng() + '',
            mapViewOnly.current.getZoom(),
          ],
        },
      };

      try {
        const resp = await Moralis.executeFunction(options);
        console.log("resp trans: " + resp.transactionHash);
      } catch (err) {
        setErrorMessage(err.message || err);
        return;
      } finally {
        // setUpdatingEvent(false);
      }
    }

    if (updateStarted === true) {
      await fetchEvent();
    }
    setUpdatingEvent(false);

  };

  const onTransferEventButtonClick = () => {
    if (!isNetworkValid()) {
      return;
    }

    setEventTransferAddress("");
    setShowTransferEventModal(true);
  };

  const transferEvent = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    setTransferringEvent(true);

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "transferEvent",
      abi: eventabi,
      params: {
        _eventId: event.eventId,
        _to: eventTransferAddress,
      },
    };

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setTransferringEvent(false);
    }
  };

  const onAddPaymentSettingButtonClick = () => {
    if (!isNetworkValid()) {
      return;
    }

    setEditingPaymentSetting(false);
    setTokenAddress("");
    setTransferAmount(0);
    setTransferAddress("");
    setBalanceAmount(0);
    setShowAddPaymentSetting(true);
  };

  const onEditPaymentSettingClick = (ps) => {
    if (!isNetworkValid()) {
      return;
    }

    setEditingPaymentSetting(true);
    setTokenAddress(ps.token.address);
    setTransferAmount(ps.transferAmount);
    setTransferAddress(ps.transferAddress);
    setBalanceAmount(ps.balanceAmount);
    setShowAddPaymentSetting(true);
  };

  const savePaymentSetting = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    setSavingPaymentSetting(true);

    let tokenMetadata = getCoinMetadata(chainId);

    if (tokenAddress !== ZERO_ADDRESS) {
      tokenMetadata = (
        await Moralis.Web3API.token.getTokenMetadata({
          chain: chainId,
          addresses: [tokenAddress],
        })
      )[0];
    }

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "saveEventPaymentSetting",
      abi: eventabi,
      params: {
        _eventId: event.eventId,
        _tokenAddress: tokenAddress,
        _paymentSetting: [
          true,
          toTokenValue(transferAmount, tokenMetadata.decimals),
          transferAddress,
          toTokenValue(balanceAmount, tokenMetadata.decimals),
        ],
      },
    };

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setSavingPaymentSetting(false);
    }

    await fetchPaymentSettings();
  };

  const deletePaymentSetting = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    setDeletingPaymentSetting(true);

    const tokenMetadata = await Moralis.Web3API.token.getTokenMetadata({
      chain: chainId,
      addresses: [tokenAddress],
    });

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "saveEventPaymentSetting",
      abi: eventabi,
      params: {
        _eventId: event.eventId,
        _tokenAddress: tokenAddress,
        _paymentSetting: [
          false,
          toTokenValue(transferAmount, tokenMetadata.decimals),
          transferAddress,
          toTokenValue(balanceAmount, tokenMetadata.decimals),
        ],
      },
    };

    try {
      await Moralis.executeFunction(options);
      await fetchPaymentSettings();
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setDeletingPaymentSetting(false);
    }
  };

  const onAddMarkerButtonClick = () => {
    setEventMarkerToAdd(null);
    setShowAddMarkerModal(true);
  };

  const onMarkerToAddOkClicked = (markerImage) => {
    setEventMarkerToAdd(markerImage);
    setShowAddMarkerConfirmModal(true);
  };

  const onBackToMarkerEditClick = () => {
    setShowAddMarkerConfirmModal(false);
    setShowAddMarkerModal(true);
  };

  const addMarker = async () => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    setAddingMarker(true);

    try {
      const imageFile = new Moralis.File("image.png", {
        base64: eventMarkerToAdd,
      });

      await imageFile.saveIPFS();

      const options = {
        contractAddress: getPinyWorldEventAddress(chainId),
        functionName: "addMarker",
        abi: eventabi,
        params: {
          _eventId: event.eventId,
          _markerImageURI: imageFile.hash(),
          _paymentParams: [ZERO_ADDRESS, 0],
        },
      };

      await Moralis.executeFunction(options);
      await fetchEventMarkerURIList();
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setAddingMarker(false);
    }
  };

  const removeEventMarker = async (markerImageURI) => {
    if (!isNetworkValid()) {
      return;
    }

    if (!event) {
      return;
    }

    setRemovingMarker(true);
    setRemoveMarkerURI(markerImageURI);

    try {
      const options = {
        contractAddress: getPinyWorldEventAddress(chainId),
        functionName: "removeMarker",
        abi: eventabi,
        params: {
          _eventId: event.eventId,
          _markerImageURI: markerImageURI,
        },
      };

      await Moralis.executeFunction(options);
      await fetchEventMarkerURIList();

      setRemoveMarkerURI("");
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setRemovingMarker(false);
    }
  };

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <section className="relative py-16 bg-gray-100">
          <div
            className="max-w-7xl px-4 mx-auto"
            style={{ marginTop: "-224px" }}
          >
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-center">
                  <h2 className="text-white text-2xl">
                    Update Event {eventId}{" "}
                    {event ? " - " + event.network : null}
                  </h2>
                </div>
              </CardHeader>
              <CardBody>
                <Tab>
                  <TabList color="lightBlue">
                    <TabItem
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      ripple="light"
                      active={openTab === 1 ? true : false}
                      href="tabItem"
                    >
                      <Icon name="language" size="lg" />
                      Event Info
                    </TabItem>
                    <TabItem
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      ripple="light"
                      active={openTab === 2 ? true : false}
                      href="tabItem"
                    >
                      <Icon name="account_circle" size="lg" />
                      Payment Settings
                    </TabItem>
                    <TabItem
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      ripple="light"
                      active={openTab === 3 ? true : false}
                      href="tabItem"
                    >
                      <Icon name="settings" size="lg" />
                      Markers
                    </TabItem>
                  </TabList>

                  <TabContent>
                    <TabPane active={openTab === 1 ? true : false}>
                      {event ? (
                        <div>
                          <div className="flex flex-row">
                            <div className="flex flex-col font-light" style={{ width: '40%' }}>
                              <div className="flex flex-row font-light mt-5">
                                <Input
                                  type="text"
                                  color="purple"
                                  placeholder="Event Name"
                                  outline={true}
                                  value={event.name}
                                  disabled
                                />
                              </div>
                              <div className="flex flex-row font-light mt-5">
                                <Input
                                  type="number"
                                  color="purple"
                                  placeholder="Maximum NFT Count"
                                  outline={true}
                                  value={event.maxMintCount}
                                  disabled
                                />
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                                className="flex flex-row font-light mt-5" >
                                <div style={{ marginRight: "16px" }}>
                                  Custom Marker Enabled
                                </div>
                                <Switch
                                  checked={customMarkerEnabled}
                                  onChange={onCustomMarkerEnabledChange}
                                />
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                                className="flex flex-row font-light mt-5" >
                                <div style={{ marginRight: "16px" }}>
                                  Event Active
                                </div>
                                <Switch
                                  checked={eventEnabled}
                                  onChange={onEventEnabledChange}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col font-light" style={{ width: '60%' }}>
                              <div className="w-full font-light mt-5">
                                <Textarea
                                  rows="4"
                                  size="3"
                                  color="purple"
                                  placeholder="Event Description"
                                  outline={true}
                                  value={event.description}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-row items-center justify-center" style={{ marginTop: "16px", marginBottom: "16px" }} >
                            {isWeb3Enabled ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Button
                                  disabled={updatingEvent}
                                  onClick={updateEvent}
                                >
                                  UPDATE{" "}
                                  {updatingEvent ? <SyncOutlined spin /> : null}
                                </Button>
                                <Button onClick={onTransferEventButtonClick} style={{ marginLeft: '10px' }}>
                                  TRANSFER
                                </Button>
                              </div>
                            ) : (
                              <Button disabled>CONNECT TO WALLET</Button>
                            )}
                          </div>

                          <div className="flex flex-row items-center justify-center mt-2">
                            <Label color="lightBlue">
                              <p style={{ textTransform: 'none' }}>
                                To change central point and zoom level, play around with map below
                              </p>
                            </Label>
                          </div>

                          <div style={{ marginTop: "16px" }}>
                            <Wrapper
                              apiKey={"AIzaSyBtVHcUCL8ZPPzvsP23n3Mby9bwGcaRKUg"}
                              language="en"
                            >
                              <GoogleMapViewOnly
                                onBoundsChanged={() => { /* console.log(JSON.stringify(mapViewOnly.current.getCenter())) */ }}
                                onZoomChanged={() => { /* console.log(mapViewOnly.current.getZoom()) */ }}
                                mapViewOnly={mapViewOnly}
                                latitude={(null !== event ? event.mapCenter.latitude : null)}
                                longitude={(null !== event ? event.mapCenter.longitude : null)}
                                zoom={(null !== event ? event.mapZoom : null)}
                              >
                              </GoogleMapViewOnly>
                            </Wrapper>
                          </div>
                        </div>

                      ) : (
                        <Skeleton active={true}></Skeleton>
                      )}
                    </TabPane>
                    <TabPane active={openTab === 2 ? true : false}>
                      {eventPaymentSettingList ? (
                        <div>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <Button onClick={onAddPaymentSettingButtonClick}>
                              Add
                            </Button>
                          </div>
                          {eventPaymentSettingList.length > 0 ? (
                            eventPaymentSettingList.map((ps, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      onEditPaymentSettingClick(ps)
                                    }
                                  >
                                    {ps.token.symbol}
                                  </div>
                                  <div>{ps.transferAmount}</div>
                                  <div>{ps.transferAddress}</div>
                                  <div>{ps.balanceAmount}</div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              No payment setting. Minting marker is free
                            </div>
                          )}
                        </div>
                      ) : (
                        <Skeleton active={true}></Skeleton>
                      )}
                    </TabPane>
                    <TabPane active={openTab === 3 ? true : false}>
                      {eventMarkerImageURIList ? (
                        <div>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <Button onClick={onAddMarkerButtonClick}>
                              Add
                            </Button>
                          </div>
                          {eventMarkerImageURIList.length > 0 ? (
                            eventMarkerImageURIList.map((miu, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <img
                                    src={`https://ipfs.io/ipfs/${miu}`}
                                    alt={miu}
                                  />
                                  <Button
                                    disabled={removingMarker}
                                    onClick={() => removeEventMarker(miu)}
                                  >
                                    DELETE{" "}
                                    {removingMarker &&
                                      removeMarkerURI === miu ? (
                                      <SyncOutlined spin />
                                    ) : null}
                                  </Button>
                                </div>
                              );
                            })
                          ) : (
                            <div>No marker found</div>
                          )}
                        </div>
                      ) : (
                        <Skeleton active={true}></Skeleton>
                      )}
                    </TabPane>
                  </TabContent>
                </Tab>
              </CardBody>
            </Card>
          </div>

          <Modal
            size="regular"
            active={showInvalidNetwork}
            toggler={() => {
              setShowInvalidNetwork(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowInvalidNetwork(false);
              }}
            >
              Invalid Network
            </ModalHeader>
            <ModalBody>
              {showInvalidNetwork && event ? (
                <div>
                  <div>
                    Please connect to{" "}
                    {getChain(getChainIdByNetwork(event.network)).name}
                  </div>

                  <div>You are connected to {getChain(chainId).name}</div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>

          <Modal
            size="regular"
            active={showTransferEventModal}
            toggler={() => {
              setShowTransferEventModal(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowTransferEventModal(false);
              }}
            >
              TRANSFER EVENT
            </ModalHeader>
            <ModalBody>
              {showTransferEventModal ? (
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full font-light mt-5">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Tranfer to Address"
                        onChange={onEventTransferAddressChange}
                        value={eventTransferAddress}
                      />
                    </div>

                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <Button
                        disabled={transferringEvent}
                        onClick={transferEvent}
                      >
                        TRANSFER{" "}
                        {transferringEvent ? <SyncOutlined spin /> : null}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>

          <Modal
            size="regular"
            active={showAddPaymentSetting}
            toggler={() => {
              setShowAddPaymentSetting(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowAddPaymentSetting(false);
              }}
            >
              Add Payment Setting
            </ModalHeader>
            <ModalBody>
              {showAddPaymentSetting ? (
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full font-light mt-5">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Token Address"
                        onChange={onTokenAddressChange}
                        value={tokenAddress}
                        disabled={editingPaymentSetting}
                      />
                    </div>
                    <div className="w-full font-light mt-5">
                      <Input
                        type="number"
                        color="purple"
                        placeholder="Transfer Amount"
                        onChange={onTransferAmountChange}
                        value={transferAmount}
                      />
                    </div>
                    <div className="w-full font-light mt-5">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Transfer Address"
                        onChange={onTransferAddressChange}
                        value={transferAddress}
                      />
                    </div>
                    <div className="w-full font-light mt-5">
                      <Input
                        type="number"
                        color="purple"
                        placeholder="Balance Amount"
                        onChange={onBalanceAmountChange}
                        value={balanceAmount}
                      />
                    </div>

                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <Button
                        disabled={
                          savingPaymentSetting || deletingPaymentSetting
                        }
                        onClick={savePaymentSetting}
                      >
                        SAVE{" "}
                        {savingPaymentSetting ? <SyncOutlined spin /> : null}
                      </Button>
                      {editingPaymentSetting ? (
                        <Button
                          disabled={
                            savingPaymentSetting || deletingPaymentSetting
                          }
                          onClick={deletePaymentSetting}
                        >
                          DELETE{" "}
                          {deletingPaymentSetting ? (
                            <SyncOutlined spin />
                          ) : null}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>

          <ModalMarkerEdit
            markerEditModal={showAddMarkerModal}
            setMarkerEditModal={setShowAddMarkerModal}
            setCurrentMarkerImage={onMarkerToAddOkClicked}
          />

          <Modal
            size="regular"
            active={showAddMarkerConfirmModal}
            toggler={() => {
              setShowAddMarkerConfirmModal(false);
            }}
          >
            <ModalHeader
              toggler={() => {
                setShowAddMarkerConfirmModal(false);
              }}
            >
              Confirm adding marker
            </ModalHeader>
            <ModalBody>
              {showAddMarkerConfirmModal && eventMarkerToAdd ? (
                <div>
                  <div>
                    <img src={eventMarkerToAdd} alt="Marker" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      disabled={addingMarker}
                      onClick={onBackToMarkerEditClick}
                    >
                      BACK TO EDIT
                    </Button>

                    <Button disabled={addingMarker} onClick={addMarker}>
                      CONFIRM {addingMarker ? <SyncOutlined spin /> : null}
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>

          <Modal
            size="regular"
            active={errorMessage ? true : false}
            toggler={() => {
              setErrorMessage(null);
            }}
          >
            <ModalHeader
              toggler={() => {
                setErrorMessage(null);
              }}
            >
              ERROR
            </ModalHeader>
            <ModalBody>
              <div>{errorMessage}</div>
            </ModalBody>
          </Modal>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
