import { Switch, Route, Redirect } from "react-router-dom";
import Landing from "pages/Landing";
import MainPage from "pages/MainPage";
import Login from "pages/Login";
import Register from "pages/Register";
import MyEvents from "pages/event/MyEvents";
import CreateEvent from "pages/event/CreateEvent";
import UpdateEvent from "pages/event/UpdateEvent";
import EventHome from "pages/event/Home";
import MyMarkers from "pages/marker/MyMarkers";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import { useMoralis } from "react-moralis";
import { useEffect } from "react";

function App({ isServerInfo }) {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Switch>
      <Route exact path="/" component={MainPage} />
      <Route exact path="/main" component={MainPage} />
      <Route exact path="/landing" component={Landing} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/event" component={MyEvents} />
      <Route exact path="/event/create" component={CreateEvent} />
      <Route exact path="/event/:eventId" component={EventHome} />
      <Route exact path="/event/update/:eventId" component={UpdateEvent} />
      <Route exact path="/marker" component={MyMarkers} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default App;
