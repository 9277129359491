import React from "react";

import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";

export default function ModalWalletConnectWarning(props) {
    return (
        <>
            <Modal size="regular" active={props.walletConnectWarningModal} toggler={() => props.setWalletConnectWarningModal(false)}>
                <ModalHeader toggler={() => props.setWalletConnectWarningModal(false)}>
                    Wallet not connected
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        Please connect your wallet to switch Map to the Pin Mode.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="red"
                        onClick={(e) => props.setWalletConnectWarningModal(false)}
                        ripple="light"
                    >
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}