import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Label from "@material-tailwind/react/Label";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/mainpage/Header";
import DefaultNavbar from "components/DefaultNavbar";
import { Steps, Spin, Switch } from "antd";
import "antd/dist/antd.css";
import { Button, Modal, ModalBody, ModalHeader } from "@material-tailwind/react";
import Radio from "@material-tailwind/react/radio";
import { useMoralis } from "react-moralis";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import eventlistenerabi from "contracts/abi/PinyWorldEventListener.json";
import erc20abi from "contracts/abi/ERC20.json";
import { useCallback, useEffect, useState, useRef } from "react";
import { tokenValue } from "helpers/formatters";
import { SyncOutlined } from "@ant-design/icons/lib/icons";
import { toTokenValue } from "helpers/formatters";
import { getPinyWorldEventAddress } from "constant/ContractAddress";
import { getPinyWorldEventListenerAddress } from "constant/ContractAddress";
import { ZERO_ADDRESS } from "helpers/formatters";
import { getCoinMetadata } from "helpers/coins";
import GoogleMapViewOnly from "components/GoogleMapViewOnly";
import { Wrapper } from "@googlemaps/react-wrapper";

export default function CreateEvent() {
  const { Moralis, isWeb3Enabled, account, chainId } = useMoralis();
  const { Step } = Steps;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const mapViewOnly = useRef(null);
  const [maxNFT, setMaxNFT] = useState("");
  const [customMarkerEnabled, setCustomMarkerEnabled] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [createEventPaymentSettingList, setCreateEventPaymentSettingList] = useState(null);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [creatingEvent, setCreatingEvent] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const onMaxNFTChange = (event) => {
    const inputAsInt = parseInt(event.target.value, 10);
    setMaxNFT(inputAsInt.toString());
  };

  const onCustomMarkerEnabledChange = (checked) => {
    setCustomMarkerEnabled(checked);
  };

  const onPaymentMethodChange = (event) => {
    if (
      !createEventPaymentSettingList ||
      event.target.value > createEventPaymentSettingList.length
    ) {
      return;
    }

    setSelectedPaymentIndex(parseInt(event.target.value));
  };

  const onSelectPaymentNextButtonClick = () => {
    if (
      !createEventPaymentSettingList ||
      (selectedPaymentIndex !== 0 && !selectedPaymentIndex)
    ) {
      return;
    }

    setPaymentMethod(createEventPaymentSettingList[selectedPaymentIndex]);
  };

  const onInsufficientBalanceBackButtonClick = () => {
    setInsufficientBalance(false);
    setPaymentMethod(null);
  };

  const onApprovePaymentBackButtonClick = () => {
    setInsufficientBalance(false);
    setPaymentMethod(null);
  };

  const onConfirmCreateBackButtonClick = () => {
    setInsufficientBalance(false);
    setPaymentMethod(null);
  };

  const fetchCreateEventPaymentSettingList = useCallback(async () => {
    if (!isWeb3Enabled) {
      return;
    }

    let options = {
      contractAddress: getPinyWorldEventListenerAddress(chainId),
      functionName: "getCreateEventPaymentSettingList",
      abi: eventlistenerabi,
    };

    const paymentSettingList = await Moralis.executeFunction(options);

    const paymentSettingWithTokenInfoList = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = getCoinMetadata(chainId);

        if (ps.tokenAddress !== ZERO_ADDRESS) {
          tokenMetadata = (
            await Moralis.Web3API.token.getTokenMetadata({
              chain: chainId,
              addresses: [ps.tokenAddress],
            })
          )[0];
        }

        const transferAmount = tokenValue(
          ps.paymentSetting.transferAmount,
          tokenMetadata.decimals
        );

        const balanceAmount = tokenValue(
          ps.paymentSetting.balanceAmount,
          tokenMetadata.decimals
        );

        if (transferAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            type: 1,
            amount: transferAmount,
          });
        }

        if (balanceAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            type: 0,
            amount: balanceAmount,
          });
        }
      })
    );

    setCreateEventPaymentSettingList(paymentSettingWithTokenInfoList);
  }, [Moralis, chainId, isWeb3Enabled]);

  const checkPaymentApproval = useCallback(async () => {
    if (!paymentMethod) {
      return;
    }

    setCheckingPaymentApproval(true);

    if (paymentMethod.type === 1) {
      if (paymentMethod.token.address !== ZERO_ADDRESS) {
        let options = {
          contractAddress: paymentMethod.token.address,
          functionName: "allowance",
          abi: erc20abi,
          params: {
            owner: account,
            spender: getPinyWorldEventListenerAddress(chainId),
          },
        };

        const allowance = tokenValue(
          await Moralis.executeFunction(options),
          paymentMethod.token.decimals
        );

        if (allowance >= paymentMethod.amount) {
          setPaymentApproved(true);
        }

        options = {
          contractAddress: paymentMethod.token.address,
          functionName: "balanceOf",
          abi: erc20abi,
          params: {
            account: account,
          },
        };

        const balance = tokenValue(
          await Moralis.executeFunction(options),
          paymentMethod.token.decimals
        );

        if (balance < paymentMethod.amount) {
          setInsufficientBalance(true);
        }
      } else if (paymentMethod.token.address === ZERO_ADDRESS) {
        setPaymentApproved(true);

        const options = { chain: chainId };
        const balance = tokenValue(
          (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
          paymentMethod.token.decimals
        );

        if (balance < paymentMethod.amount) {
          setInsufficientBalance(true);
        }
      }
    }

    setCheckingPaymentApproval(false);
  }, [Moralis, account, chainId, paymentMethod]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  useEffect(() => {
    fetchCreateEventPaymentSettingList();
  }, [fetchCreateEventPaymentSettingList]);

  const onCreateButtonClick = () => {
    if (!createEventPaymentSettingList) {
      return;
    }

    if (createEventPaymentSettingList.length === 1) {
      setPaymentMethod(createEventPaymentSettingList[0]);
    }

    setErrorMessage("");
    setShowConfirmDialog(true);
  };

  const approvePaymentMethodClicked = async () => {
    setApprovingPayment(true);

    const options = {
      contractAddress: paymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldEventListenerAddress(chainId),
        amount: toTokenValue(
          paymentMethod.amount,
          paymentMethod.token.decimals
        ),
      },
    };

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err);
      return;
    } finally {
      setApprovingPayment(false);
    }

    setPaymentApproved(true);
  };

  const submitCreateEventClicked = async () => {
    if (
      !createEventPaymentSettingList ||
      (createEventPaymentSettingList.length > 0 && !paymentMethod)
    ) {
      return;
    }

    setCreatingEvent(true);

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "createEvent",
      abi: eventabi,
      params: {
        _eventParams: [
          name,
          description,
          maxNFT ? maxNFT : "0",
          customMarkerEnabled,
          mapViewOnly.current.getCenter().lat() + '',
          mapViewOnly.current.getCenter().lng() + '',
          mapViewOnly.current.getZoom(),
        ],
        _paymentParams: [paymentMethod.token.address, paymentMethod.type],
      },
    };

    if (paymentMethod.token.address === ZERO_ADDRESS) {
      options["msgValue"] = toTokenValue(
        paymentMethod.amount,
        paymentMethod.token.decimals
      );
    }

    try {
      await Moralis.executeFunction(options);
    } catch (err) {
      setErrorMessage(err.message || err);
      return;
    } finally {
      setCreatingEvent(false);
    }

  };

  const computeCreateEventStep = () => {
    if (!createEventPaymentSettingList) {
      return 0;
    }

    if (!createEventPaymentSettingList.length) {
      return 0;
    }

    if (createEventPaymentSettingList.length > 1) {
      if (!paymentMethod) {
        return 0;
      }

      if (!paymentApproved || checkingPaymentApproval || insufficientBalance) {
        return 1;
      }

      return 2;
    }

    if (!paymentApproved) {
      return 0;
    }

    return 1;
  };

  const computeCreateEventStepContent = () => {
    if (!createEventPaymentSettingList) {
      return null;
    }

    if (!createEventPaymentSettingList.length) {
      return null;
    }

    if (createEventPaymentSettingList.length > 1) {
      return (
        <Steps current={computeCreateEventStep()}>
          <Step title="Select payment method" />
          <Step title="Approve payment method" />
          <Step title="Confirm" />
        </Steps>
      );
    }

    return (
      <Steps current={computeCreateEventStep()}>
        <Step title="Approve payment method" />
        <Step title="Confirm" />
      </Steps>
    );
  };

  const fetchingPaymentMethodContent = () => {
    return <div>Fetching payment methods...</div>;
  };

  const selectPaymentMethodContent = () => {
    return (
      <div>
        <div>Please select payment method</div>
        <div>
          {createEventPaymentSettingList.map((ps, index) => {
            return (
              <Radio
                onChange={onPaymentMethodChange}
                key={index}
                value={index}
                id={`paymentMethod${index}`}
                color="lightBlue"
                text={`${ps.amount} ${ps.token.symbol} ${ps.type === 1 ? "Transfer" : "Bakiye"
                  }`}
                name="paymentMethod"
                checked={selectedPaymentIndex === index}
              />
            );
          })}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button onClick={onSelectPaymentNextButtonClick}>NEXT</Button>
        </div>
      </div>
    );
  };

  const insufficientBalanceContent = () => {
    if (!paymentMethod) {
      return <Spin />;
    }

    return (
      <div>
        <div>INSUFFICIENT BALANCE</div>
        <div>Event Creation Fee</div>
        <div>
          {paymentMethod.amount} {paymentMethod.token.name}
        </div>
        {createEventPaymentSettingList &&
          createEventPaymentSettingList.length > 1 ? (
          <Button onClick={onInsufficientBalanceBackButtonClick}>BACK</Button>
        ) : null}
      </div>
    );
  };

  const approvePaymentContent = () => {
    if (paymentMethod) {
      return (
        <div>
          <div>Event Creation Fee</div>
          <div>
            {paymentMethod.amount} {paymentMethod.token.symbol}
          </div>
          <div>
            {insufficientBalance ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {createEventPaymentSettingList &&
                  createEventPaymentSettingList.length > 1 ? (
                  <Button
                    disabled={checkingPaymentApproval || approvingPayment}
                    onClick={() => onApprovePaymentBackButtonClick()}
                  >
                    BACK
                  </Button>
                ) : null}
                <Button
                  disabled={checkingPaymentApproval || approvingPayment}
                  onClick={() => approvePaymentMethodClicked()}
                >
                  APPROVE{" "}
                  {checkingPaymentApproval || approvingPayment ? (
                    <SyncOutlined spin />
                  ) : null}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const confirmCreateEventContent = () => {
    return (
      <div>
        <div>Event Creation Fee</div>
        <div>
          {paymentMethod.amount} {paymentMethod.token.name}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {createEventPaymentSettingList &&
            createEventPaymentSettingList.length > 1 ? (
            <Button
              disabled={checkingPaymentApproval || approvingPayment}
              onClick={() => onConfirmCreateBackButtonClick()}
            >
              BACK
            </Button>
          ) : null}
          <Button
            disabled={creatingEvent}
            onClick={() => submitCreateEventClicked()}
          >
            CREATE {creatingEvent ? <SyncOutlined spin /> : null}
          </Button>
        </div>
      </div>
    );
  };

  const errorContent = () => {
    return (
      <div>
        <div>{errorMessage}</div>
      </div>
    );
  };

  const computeConfirmDialogContent = () => {
    if (errorMessage) {
      return errorContent();
    }

    if (insufficientBalance) {
      return insufficientBalanceContent();
    }

    if (!createEventPaymentSettingList) {
      return fetchingPaymentMethodContent();
    }

    if (checkingPaymentApproval) {
      return approvePaymentContent();
    }

    if (createEventPaymentSettingList.length > 1) {
      if (!paymentMethod) {
        return selectPaymentMethodContent();
      }
    }

    if (createEventPaymentSettingList.length > 0) {
      if (!paymentApproved) {
        return approvePaymentContent();
      }
    }

    return confirmCreateEventContent();
  };

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <section className="relative py-16 bg-gray-100">
          <div
            className="max-w-7xl px-4 mx-auto"
            style={{ marginTop: "-224px" }}
          >
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-center">
                  <h2 className="text-white text-2xl">Create Event</h2>
                </div>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "8px",
                    marginBottom: "32px",
                    width: "90%",
                  }}
                >
                  <Steps current={0}>
                    <Step title="Create Event" />
                    <Step title="Add Markers" />
                  </Steps>
                </div>
                <form>
                  <div className="flex flex-row items-center justify-center">
                    <h6 className="text-purple-500 text-sm font-light uppercase">
                      Event Information
                    </h6>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-col font-light" style={{ width: '40%' }}>
                      <div className="flex flex-row font-light mt-5">
                        <Input
                          type="text"
                          color="purple"
                          placeholder="Event Name"
                          outline={true}
                          onChange={onNameChange}
                          value={name}
                        />
                      </div>
                      <div className="flex flex-row font-light mt-5">
                        <Input
                          type="number"
                          color="purple"
                          placeholder="Maximum NFT Count"
                          outline={true}
                          onChange={onMaxNFTChange}
                          value={maxNFT}
                        />
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        className="flex flex-row font-light mt-5 items-center justify-center"
                      >
                        <div style={{ marginRight: "16px" }}>
                          Custom Marker Enabled
                        </div>
                        <Switch
                          checked={customMarkerEnabled}
                          onChange={onCustomMarkerEnabledChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col font-light" style={{ width: '60%' }}>
                      <div className="w-full font-light mt-5">
                        <Textarea
                          rows="4"
                          size="3"
                          color="purple"
                          placeholder="Event Description"
                          outline={true}
                          onChange={onDescriptionChange}
                          value={description}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center mt-2">
                    <Label color="lightBlue">
                      <p style={{ textTransform: 'none' }}>
                        To change central point and zoom level, play around with map below
                      </p>
                    </Label>
                  </div>
                </form>
                <div style={{ marginTop: "16px" }}>
                  <Wrapper
                    apiKey={"AIzaSyBtVHcUCL8ZPPzvsP23n3Mby9bwGcaRKUg"}
                    language="en"
                  >
                    <GoogleMapViewOnly
                      onBoundsChanged={() => { }}
                      onZoomChanged={() => { }}
                      mapViewOnly={mapViewOnly}
                    >
                      {/* {pinModeActive ? marker : eventMarkerList} */}
                    </GoogleMapViewOnly>
                  </Wrapper>
                </div>
              </CardBody>

              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                {isWeb3Enabled ? (
                  <Button onClick={() => onCreateButtonClick()}>CREATE</Button>
                ) : (
                  <Button disabled>CONNECT TO WALLET</Button>
                )}
              </div>
            </Card>
          </div>
          <Modal
            size="regular"
            active={showConfirmDialog}
            toggler={() => setShowConfirmDialog(false)}
          >
            <ModalHeader toggler={() => setShowConfirmDialog(false)}>
              CONFIRM CREATE EVENT
            </ModalHeader>
            <ModalBody>
              {showConfirmDialog ? (
                <div>
                  <div>{computeCreateEventStepContent()}</div>
                  <div>{computeConfirmDialogContent()}</div>
                </div>
              ) : (
                <div>
                  <Spin />
                </div>
              )}
            </ModalBody>
          </Modal>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
