import React from "react";
import { useMoralis } from "react-moralis";
import Blockies from "react-blockies";
import { Skeleton } from "antd";
import "assets/styles/pinyworld.css";

export default function Blockie(props) {
  const { account } = useMoralis();
  if (!props.address && !account) return <Skeleton.Avatar active size={40} />;

  return (
    <Blockies
      seed={
        props.currentWallet
          ? account.toLowerCase()
          : props.address.toLowerCase()
      }
      className="blockie"
      {...props}
    />
  );
}
