import React, { useState, useEffect, useCallback } from "react";
import Button from '@material-tailwind/react/Button';
import Nav from '@material-tailwind/react/Nav';
import { useSelector, useDispatch } from 'react-redux';
import { useMoralis } from "react-moralis";
import erc20abi from "../contracts/abi/ERC20.json";
import pinyworldnftabi from "../contracts/abi/PinyWorldNFT.json";
import mintlistenerabi from "../contracts/abi/PinyWorldMintListener.json";
import * as pinyActions from "../store/actions/actionPiny";
import { getPinyWorldNftAddress, getPinyWorldMintListenerAddress, getUSDTTokenAddress } from "constant/ContractAddress";

export default function MintActions() {

    const { walletConnected, currentMarkerImage, currentMarkerInfo } = useSelector(state => state.piny);
    const markerPosition = currentMarkerInfo.markerPosition;
    const mapZoom = currentMarkerInfo.mapZoom;
    const markerName = currentMarkerInfo.markerName;
    const markerMessage = currentMarkerInfo.markerMessage;


    const { isAuthenticated, Moralis, account, isWeb3Enabled, chainId } = useMoralis();

    const [paymentAmount, setPaymentAmount] = useState();
    const [paymentApproved, setPaymentApproved] = useState(false);

    const dispatch = useDispatch();
    const pinyworldNFT = getPinyWorldNftAddress(chainId);
    const paymentTokenAddress = getUSDTTokenAddress(chainId);
    const mintListener = getPinyWorldMintListenerAddress(chainId);

    const checkPaymentApproved = useCallback(async () => {
        console.log("[MinActions.js] in checkPaymentApproved() - isWeb3Enabled: " + isWeb3Enabled + ", account: " + account);
        console.log(JSON.stringify(chainId));
        if (!isWeb3Enabled || !account) {
            return;
        }

        let options = {
            contractAddress: mintListener,
            functionName: "getPaymentSetting",
            abi: mintlistenerabi,
            params: {
                _tokenAddress: paymentTokenAddress,
            },
        };

        const paymentSetting = await Moralis.executeFunction(options);

        // console.log("[MinActions.js] in getPaymentSetting() result: " + paymentSetting);
        
        setPaymentAmount(paymentSetting.transferAmount);

        options = {
            contractAddress: paymentTokenAddress,
            functionName: "allowance",
            abi: erc20abi,
            params: {
                owner: account,
                spender: mintListener,
            },
        };

        const allowance = await Moralis.executeFunction(options);

        console.log("[MinActions.js] in allowance() result: " + allowance);

        if (allowance >= paymentSetting.amount) {
            setPaymentApproved(true);
        }
        
    }, [Moralis, account, isWeb3Enabled]);

    const onApprovePayment = async () => {
        const options = {
            contractAddress: paymentTokenAddress,
            functionName: "approve",
            abi: erc20abi,
            params: {
                spender: mintListener,
                amount: paymentAmount,
            },
        };

        console.log(options);
        await Moralis.executeFunction(options);

        setPaymentApproved(true);
    };

    const onSubmit = async () => {
        const imageFile = new Moralis.File("image.png", {
            base64: currentMarkerImage,
        });
        await imageFile.saveIPFS();

        const metadata = {
            name: markerName,
            description: markerMessage,
            image: "ipfs://" + imageFile.hash(),
            attributes: [
                { trait_type: "Latitude", value: markerPosition.lat() },
                { trait_type: "Longitude", value: markerPosition.lng() },
                { trait_type: "Map Zoom", value: mapZoom },
            ],
        };

        const metadataFile = new Moralis.File("metadata.json", {
            base64: btoa(JSON.stringify(metadata)),
        });

        await metadataFile.saveIPFS();

        const options = {
            contractAddress: pinyworldNFT,
            functionName: "mint",
            abi: pinyworldnftabi,
            params: {
                _markerProps: [
                    markerName,
                    markerMessage,
                    "" + markerPosition.lat(),
                    "" + markerPosition.lng(),
                    mapZoom,
                    imageFile.hash(),
                ],
                _tokenURI: metadataFile.hash(),
                _paymentParams: [paymentTokenAddress, 1],
            },
        };

        await Moralis.executeFunction(options);

        console.log("MINT FINISHED ----------------------");
    };

    useEffect(() => {
        console.log("[MinActions.js] in useEffect() - walletConnected: " + walletConnected + " - isAuthenticated: " + isAuthenticated);
        checkPaymentApproved();
        if (walletConnected !== isAuthenticated) {
            dispatch(pinyActions.changeWalletConnected(isAuthenticated));
        }
    }, [checkPaymentApproved, isAuthenticated]);

    return (
        <>
            <Nav>
                {
                    paymentApproved ?
                        (
                            <Button
                                color="green"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={onSubmit}
                            >
                                <i className="fas fa-map-marker fa-2x"></i>&nbsp; Submit
                            </Button>
                        )
                        :
                        (
                            <Button
                                color="green"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={onApprovePayment}
                            >
                                <i className="fas fa-map-marker fa-2x"></i>&nbsp; Approve Payment
                            </Button>
                        )
                }
            </Nav>
        </>
    );
}