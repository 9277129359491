import React, { useState } from "react";
import NavItem from "@material-tailwind/react/NavItem";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import { useMoralis } from "react-moralis";
import { useDispatch } from "react-redux";
import { getEllipsisTxt } from "../helpers/formatters";
import * as pinyActions from "../store/actions/actionPiny";
import Blockie from "./Blockie";
import WalletAddress from "./WalletAddress";
import { Dropdown, DropdownItem } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function WalletInfo() {
  const dispatch = useDispatch();

  const { authenticate, isAuthenticated, logout, account, chainId } =
    useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const logoutWallet = () => {
    setIsModalVisible(false);
    logout();
    dispatch(pinyActions.afterLogout());
  };

  if (!isAuthenticated) {
    return (
      <div style={{ cursor: "pointer" }}>
        <NavItem
          onClick={() =>
            authenticate({ signingMessage: "Welcome to PinyWorld!" })
          }
        >
          <Icon family="font-awesome" name="fas fa-sign-in-alt" size="xl" />
          &nbsp;Connect Wallet
        </NavItem>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <NavItem ripple="light">
          <p style={{ marginRight: "5px" }}>{getEllipsisTxt(account, 6)}</p>
        </NavItem>
        <div className="-mr-4 ml-6">
          <Dropdown
            color="transparent"
            buttonText={
              <div className="w-12">
                <Blockie currentWallet scale={3} />
              </div>
            }
            rounded
            placement="bottom-end"
            style={{
              padding: 0,
              color: "transparent",
            }}
          >
            <Link to="/event">
              <DropdownItem color="lightBlue">My Events</DropdownItem>
            </Link>
            <Link to="/marker">
              <DropdownItem color="lightBlue">My Markers</DropdownItem>
            </Link>
            <Link to="/profile">
              <DropdownItem color="lightBlue">My Profile</DropdownItem>
            </Link>
            <DropdownItem
              color="lightBlue"
              onClick={() => setIsModalVisible(true)}
            >
              Logout
            </DropdownItem>
          </Dropdown>
        </div>
      </div>

      <Modal
        size="regular"
        active={isModalVisible}
        toggler={() => setIsModalVisible(false)}
      >
        <ModalHeader toggler={() => setIsModalVisible(false)}>
          Account Info
        </ModalHeader>
        <ModalBody>
          <WalletAddress
            avatar="left"
            size={6}
            copyable
            style={{ fontSize: "20px", width: "300px" }}
          />
        </ModalBody>
        <ModalFooter>
          <div style={{ width: "100%" }}>
            <Button
              color="red"
              buttonType="filled"
              onClick={(e) => logoutWallet()}
              ripple="dark"
              style={{ width: "100%" }}
            >
              Disconnect Wallet
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
