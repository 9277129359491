import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Image from "@material-tailwind/react/Image";
import { Button } from "@material-tailwind/react";
import DefaultFooter from "components/DefaultFooter";
import DefaultNavbar from "components/DefaultNavbar";
import Header from "components/mainpage/Header";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { Skeleton } from "antd";

export default function MyEvents() {
  const { isWeb3Enabled, account } = useMoralis();

  const [myEventList, setMyEventList] = useState(null);

  const fetchMyEvents = useCallback(async () => {
    if (!isWeb3Enabled || !account) {
      return;
    }

    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria",
        {
          criteria: {
            ownerList: [account],
          },
        }
      );

      setMyEventList(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [account, isWeb3Enabled]);

  useEffect(() => {
    fetchMyEvents();
  }, [fetchMyEvents]);

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <section className="relative py-16 bg-gray-100">
          <div
            className="max-w-7xl px-4 mx-auto"
            style={{ marginTop: "-224px" }}
          >
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">My Events</h2>
                  <Link to="/event/create">
                    <Button
                      color="transparent"
                      buttonType="link"
                      size="lg"
                      style={{ padding: 0 }}
                    >
                      Create Event
                    </Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Event Name
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Create Date
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Network
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Markers
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Max Mint Count
                        </th>
                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Active
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!myEventList ? (
                        <tr>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <div className="flex">
                              <div className="w-10 h-10 rounded-full border-2 border-white">
                                <Skeleton.Avatar active={true} />
                              </div>
                              <div className="w-10 h-10 rounded-full border-2 border-white -ml-4">
                                <Skeleton.Avatar active={true} />
                              </div>
                            </div>
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                          <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            <Skeleton.Input
                              active={true}
                              style={{ width: "100px" }}
                            />
                          </td>
                        </tr>
                      ) : myEventList.length > 0 ? (
                        myEventList.map((event, index) => {
                          return (
                            <tr key={index}>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                <Link to={`/event/update/${event.id}`}>
                                  {event.name}
                                </Link>
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {event.createTime}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {event.network}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                <div className="flex">
                                  {event.markerImageList &&
                                    event.markerImageList.map(
                                      (markerImage, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="w-10 h-10 rounded-full border-2 border-white"
                                          >
                                            <Image
                                              src={`https://ipfs.io/ipfs/${markerImage.urlPath}`}
                                              rounded
                                              alt="..."
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {event.maxMintCount}
                              </td>
                              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {event.enabled ? "Yes" : "No"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
